import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import {NavigationEnd, Router} from '@angular/router';
import {NotificationsData} from '../../models/NotificationsData';

import {AuthService} from '../../../services/auth.service';
import {CommerceService} from 'src/app/services/commerce.service';
import {ConfigurationService} from 'src/app/pages/configurations/services/configuration.service';
import {ContactService} from '../../../services/contact.service';
import {FcmService} from 'src/app/services/fcm.service';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {MenuService} from 'src/app/services/menu.service';
import {SpinnerService} from '../../../services/spinner.service';
import {StoreData} from '../../models/auth/StoreData';
import {Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ContactForm} from '../../models/ContactForm';
import {ToastService} from '../../../services/toast.service';

interface Question {
  option: string;
}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  currentUrl: string;
  showRegister: boolean;
  showExit = false;
  display = false;
  contactForm: FormGroup;
  contactDialog: boolean;
  submitted: boolean;
  displayLoading = false;
  questions: Question[];
  storeData: StoreData;
  url: string;
  isHome: boolean;
  subs: Subscription[] = [];
  maxLengthName: number;
  maxLengthEmail: number;
  formHasBeenSubmitted = false;
  notificationsCounter: NotificationsData = null;
  nameLength = 0;
  emailLength = 0;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private configurationService: ConfigurationService,
    private contactSrv: ContactService,
    private confirmationService: ConfirmationService,
    private authSrv: AuthService,
    private spinnerService: SpinnerService,
    private validateInputService: InputValidationService,
    private commerceService: CommerceService,
    private fcmSrv: FcmService,
    private menuService: MenuService,
    private toastSrv: ToastService
  ) {
    this.questions = [
      {option: 'Comercial'},
      {option: 'Error'},
      {option: 'Sugerencia'},
    ];
    this.maxLengthName = environment.maxNavbarContactName;
    this.maxLengthEmail = environment.maxNavbarContactEmail;
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      content: ['', Validators.required],
      reason: ['Comercial', Validators.required],
    });
  }

  get nameField(): AbstractControl {
    return this.contactForm.get('name');
  }

  get emailField(): AbstractControl {
    return this.contactForm.get('email');
  }

  get contentField(): AbstractControl {
    return this.contactForm.get('content');
  }

  get reasonField(): AbstractControl {
    return this.contactForm.get('reason');
  }

  ngOnInit(): void {
    this.showExit = this.isLoggedIn();
    this.url = environment.landingUrl;
    this.storeData = this.authSrv.getLocalUserData();
    this.emailField.patchValue(this.storeData.email);

    this.subs.push(
      this.router.events.subscribe({
        next: (data) => this.onUrlChange(data)
      }),
      this.authSrv.user.subscribe({
        next: (user: StoreData) => {
          this.storeData = user;
        }
      })
    );
    this.isLoggedIn();
  }

  public isLoggedIn(): boolean {
    const logged = localStorage.getItem('isLoggedIn');
    if (logged) {
      this.isHome = true;
      return true;
    } else {
      this.isHome = false;
      return false;
    }
  }

  onUrlChange(data) {
    if (data instanceof NavigationEnd) {
      if (window.innerWidth <= environment.limitLockSidebar) {
        this.menuService.lockMenu();
      }
      this.currentUrl = data.url;
      if (this.currentUrl === '/login') {
        this.showRegister = true;
      } else if (this.currentUrl === '/register') {
        this.showRegister = false;
      } else if (this.currentUrl === '/passwordreset') {
        this.showRegister = true;
      } else {
        this.showRegister = false;
      }
    }
    this.showExit = this.isLoggedIn();
  }

  openContact() {
    this.display = true;
    this.contactDialog = true;
    this.submitted = false;
    this.emailField.patchValue(this.storeData.email);
  }

  hideDialog() {
    this.display = false;
    this.submitted = false;
    this.contactForm.reset();
  }

  submitContact() {
    this.formHasBeenSubmitted = true;
    if (this.contactForm.valid) {
      const payload = this.prepareFormContact();
      this.spinnerService.loadSpinner.next(true);
      this.displayLoading = true;
      this.subs.push(
        this.contactSrv.sendMailContact(payload).subscribe({
          next: () => {
            this.contactForm.reset();
            this.display = false;
            this.spinnerService.loadSpinner.next(false);
            this.toastSrv.generateToast('success', 'Mensaje enviado', 'El mensaje se ha enviado correctamente.');
          },
          error: () => {
            this.spinnerService.loadSpinner.next(false);
          }
        })
      );
      this.formHasBeenSubmitted = false;
    }
  }

  prepareFormContact(): ContactForm {
    const contactForm = new ContactForm();
    contactForm.name = this.nameField.value;
    contactForm.email = this.emailField.value;
    contactForm.content = this.contentField.value;
    contactForm.reason = this.reasonField.value;
    contactForm.storeId = JSON.parse(localStorage.getItem('storeData')).id;
    return contactForm;
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
  }

  validateInputClass(
    form: FormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  handleDataName(event) {
    this.contactForm.get('name').setValue(event);
    this.nameLength = event.length;
  }
}
