import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-store-verification',
  templateUrl: './store-verification.component.html',
  styleUrls: ['./store-verification.component.scss']
})
export class StoreVerificationComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    const logged = localStorage.getItem('isLoggedIn');
    if (logged) {
      this.router.navigate(['events', 'list']);
    }
  }

}
