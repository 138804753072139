import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {MenuRequest} from '../core/models/orders/MenuRequest';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  private menuLocked = new BehaviorSubject<boolean>(false);
  menuLocked$ = this.menuLocked.asObservable();

  constructor(
    private httpC: HttpClient,
    private authService: AuthService
  ) {
  }

  public lockMenu() {
    this.menuLocked.next(true);
  }

  // MENU
  public getMenu(): Observable<any> {
    const {id} = this.authService.getLocalUserData();
    return this.httpC.get(environment.apiAGUrl + '/menu/' + id);
  }

  public createMenu(form: MenuRequest) {
    const {id} = this.authService.getLocalUserData();
    return this.httpC.post<MenuRequest>(environment.apiAGUrl + '/menu/' + id, form);
  }
}
