import {AbstractControl, FormBuilder, FormGroup, Validators,} from '@angular/forms';
import {Component, ElementRef, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {Subject, Subscription} from 'rxjs';

import {AuthService} from 'src/app/services/auth.service';
import {CommerceService} from 'src/app/services/commerce.service';
import {ConfirmedValidator} from 'src/app/shared/services/confirmed.validator';
import {Country} from '../../../shared/enums/Country';
import {InputValidationService} from 'src/app/services/input-validation.service';
import {LoadImgCroppComponent} from 'src/app/shared/components/load-img-cropp/load-img-cropp.component';
import {SignUpRequest} from 'src/app/core/models/auth/SignUpRequest';
import {Router} from '@angular/router';
import {SpinnerService} from 'src/app/services/spinner.service';
import {TermsPolicies} from 'src/app/core/models/TermsPolicies';
import {ValidatorCnpjService} from '../../../services/validator-cnpj.service';
import {ValidatorCuitService} from '../../../services/validator-cuit.service';
import {ValidatorNifService} from '../../../services/validator-nif.service';
import {ValidatorService} from '../../../services/validator.service';
import {environment} from 'src/environments/environment';
import {aspectRadioMap, CroppImgConfig,} from '../../../core/models/configurations/croppImgConfig';
import {PasswordService} from 'src/app/services/password.service';
import {ImageService} from '../../../services/image.service';
import { ValidatorTinService } from 'src/app/services/validator-tin.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {
  @ViewChild('myInput') myInputVariable: ElementRef;
  @ViewChild(LoadImgCroppComponent) loadImgCropp: LoadImgCroppComponent;
  @ViewChild(LoadImgCroppComponent) imgCroppChild: LoadImgCroppComponent;
  mediaUrlBase: string = environment.mediaUrl;
  registerForm: FormGroup;
  uploadedFiles: any[] = [];
  submitting = false;
  thumbnail;
  croppedImage: any = '';
  croppConfig: CroppImgConfig = {
    aspectRatio: aspectRadioMap.square,
    cropperMaxWidth: '3840',
    cropperMaxHeight: '3840',
    maintainAspectRatio: true,
  };

  displayTerms: boolean;
  textHeaderTerms: string;
  contentTerms: string;
  lastUpdate: Date;
  terms: TermsPolicies[];
  userDetails: object[] = [];
  confirmDialog: boolean;
  subs: Subscription[] = [];
  changingLogo = false;
  errorImage = '';
  imageHasError = false;
  formHasBeenSubmitted = false;
  maxCommerceNameLength: number;
  maxCommerceDescriptionLength: number;
  maxCommercePasswordLength: number;
  minCommercePasswordLength: number;
  maxPhoneLength: number;
  errorRequest = '';
  imageRequestHasError = false;
  isReadOnly = true;
  storeImageUrl: any = null;
  storeImageUploadSuccess: Subject<boolean> = new Subject<boolean>();
  storeImageUploading: boolean;
  storeImageUploaded = false;
  countries: any;
  searchingCountries = false;
  passwordRegex: string;
  passWordNotEquals = false;
  passwordMatch = false;
  imagePath;
  current = 0;
  max = 100;
  baseUrlUploadImage: string;
  cityDisabled = true;

  constructor(
    private formBuilder: FormBuilder,
    private commerceService: CommerceService,
    private authService: AuthService,
    private router: Router,
    private spinnerService: SpinnerService,
    private validateInputService: InputValidationService,
    private validatorSrv: ValidatorService,
    private validatorCnpj: ValidatorCnpjService,
    private validatorCuit: ValidatorCuitService,
    private validatorNif: ValidatorNifService,
    private validatorTin: ValidatorTinService,
    private passwordService: PasswordService,
    private imageSrv: ImageService
  ) {
    this.maxCommerceNameLength = environment.maxCommerceNameLength;
    this.maxCommerceDescriptionLength =
      environment.maxCommerceDescriptionLength;
    this.maxPhoneLength = environment.maxPhoneLength;

    this.registerForm = this.formBuilder.group(
      {
        country: ['', [Validators.required]],
        taxIdentifier: ['', [Validators.required]],
        name: ['', [Validators.required]],
        address: ['', Validators.required],
        phone: ['', [Validators.required, this.validatorSrv.phoneValidator()]],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
          ],
        ],
        latitude: [''],
        longitude: [''],
        password: [
          '',
          [Validators.required, Validators.pattern('(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,32}')],
        ],
        confirmPassword: ['', [Validators.required]],
        city: [{value: null, disabled: true}, Validators.required],
        logo: [null, Validators.required],
        acceptTerms: [false, Validators.requiredTrue],
      },
      {
        validator: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  get countryField(): AbstractControl {
    return this.registerForm.get('country');
  }

  get taxIdentifierField(): AbstractControl {
    return this.registerForm.get('taxIdentifier');
  }

  get logoField(): AbstractControl {
    return this.registerForm.get('logo');
  }

  get cityField(): AbstractControl {
    return this.registerForm.get('city');
  }

  get passwordField(): AbstractControl {
    return this.registerForm.get('password');
  }

  get confirmPasswordField(): AbstractControl {
    return this.registerForm.get('confirmPassword');
  }

  async ngOnInit() {
    await this.getCountries();
    const logged = localStorage.getItem('isLoggedIn');
    if (logged) {
      this.router.navigate(['events', 'list']);
    }
    setTimeout(() => {
      this.isReadOnly = false;
    }, 2000);

    this.passwordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });

    this.confirmPasswordField.valueChanges.subscribe(res => {
      this.passwordMatch = true;
    });
  }

  async getCountries() {
    this.searchingCountries = true;
    this.countries = [];
    const availableCountries = await this.commerceService
      .getAvailableCountries()
      .toPromise();
    for (const value of Object.values(availableCountries)) {
      if (!Number.isNaN(Number(value))) {
        continue;
      }
      this.countries.push({id: value, name: value});
    }
    setTimeout(() => {
      this.searchingCountries = false;
    }, 200);
  }

  countryChange() {
    this.registerForm.get('taxIdentifier').reset();
    if (this.countryField.value === Country.ARGENTINA) {
      this.registerForm.controls.taxIdentifier.clearValidators();
      this.registerForm.controls.taxIdentifier.addValidators([
        Validators.required,
        this.cuitValidator(),
      ]);
    } else if (this.countryField.value === Country.BRASIL) {
      this.registerForm.controls.taxIdentifier.clearValidators();
      this.registerForm.controls.taxIdentifier.addValidators([
        Validators.required,
        this.cnpjValidator(),
      ]);
    } else if (this.countryField.value === Country.ESPAÑA) {
      this.registerForm.controls.taxIdentifier.clearValidators();
      this.registerForm.controls.taxIdentifier.addValidators([
        Validators.required,
        this.nifValidator(),
      ]);
    } else if (this.countryField.value === Country.EEUU) {
      this.registerForm.controls.taxIdentifier.clearValidators();
      this.registerForm.controls.taxIdentifier.addValidators([
        Validators.required,
        this.tinValidator(),
      ]);
    }
  }

  displayProperty(prop) {
    const valid = [
      'taxIdentifier',
      'name',
      'address',
      'phone',
      'email',
      'city',
    ];
    return valid.includes(prop);
  }

  confirmUserDetails() {
    this.formHasBeenSubmitted = true;
    this.errorRequest = '';

    if (this.passwordField.value !== this.confirmPasswordField.value) {
      this.passwordMatch = false;
    }

    this.userDetails = [];
    Object.keys(this.registerForm.controls).forEach(key => {
      this.userDetails.push({
        key,
        value: this.registerForm.controls[key].value,
      });
    });

    if (this.registerForm.valid && this.passwordMatch && !this.storeImageUploading) {
      this.confirmDialog = true;
    } else {
      this.submitting = false;
    }
  }

  goToLink(type: string) {
    if (type === 'terms') {
      window.open(
        environment.termsAndConditions,
        '_blank'
      );
    } else if (type === 'policies') {
      window.open(environment.privacyPolicies, '_blank');
    }
  }

  validateCountry(country: string) {
    return this.countryField.value === country;
  }

  onKeyDownPhone(e) {
    return (
      (e.keyCode >= 48 && e.keyCode <= 57) ||
      (e.keyCode >= 96 && e.keyCode <= 105) ||
      e.keyCode === 8 ||
      e.keyCode === 37 ||
      e.keyCode === 39 ||
      e.keyCode === 9
    );
  }

  submitRegister() {
    if (this.registerForm.valid) {
      this.confirmDialog = false;
      this.submitting = true;
      this.spinnerService.loadSpinner.next(true);
      this.sendData();
    }
  }

  sendData() {
    let finalCuit = this.taxIdentifierField.value.replaceAll('-', '');
    finalCuit = finalCuit.replaceAll('.', '');
    finalCuit = finalCuit.replaceAll('/', '');
    const payload = this.registerForm.getRawValue();
    const form: SignUpRequest = {
      ...payload,
      idCategoria: 1,
      taxIdentifier: finalCuit,
      logo: payload.logo,
      phone: Number(payload.phone),
      country: payload.country,
      city: payload.city
    };
    this.subs.push(
      this.authService.register(form).subscribe(
        d => {
          const data = 'successfulRegistration';
          this.spinnerService.loadSpinner.next(false);
          this.router.navigate(['/confirm-registration'], {
            state: {data, email: form.email},
          });
        },
        err => {
          if (err.status === 400) {
            this.errorRequest =
              'Ya existe una cuenta registrada con el email ingresado.';
          } else {
            this.errorRequest =
              'Error al intentar crear el usuario, vuelva a intentarlo.';
          }
          this.submitting = false;
          this.spinnerService.loadSpinner.next(false);
        }
      )
    );
  }

  removeThumbnail() {
    this.imageRequestHasError = false;
    this.uploadedFiles = null;
    this.croppedImage = null;
    this.myInputVariable.nativeElement.value = '';
    this.logoField.reset();
    this.thumbnail = [];
  }

  cuitValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let cuit: string;
      if (
        control.value !== '' &&
        control.value !== null &&
        this.registerForm.get('country').value === Country.ARGENTINA
      ) {
        cuit = control.value.split('-').join('');
      }
      return control.value && !this.validatorCuit.validCuit(cuit)
        ? {cuitValidator: true}
        : null;
    };
  }

  cnpjValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const cnpj: string = control.value;
      const cnpjIsValid = this.validatorCnpj.validateCNPJ(cnpj);
      return control.value && !cnpjIsValid ? {cnpjValidator: true} : null;
    };
  }

  nifValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const cif: string = control.value;
      const cifIsValid = this.validatorNif.validateNIF(cif);
      return control.value && !cifIsValid ? {cifValidator: true} : null;
    };
  }

  tinValidator() {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const tin: string = control.value;
      const tinIsValid = this.validatorTin.validateTin(tin);
      return control.value && !tinIsValid ? {tinValidator: true} : null;
    };
  }

  changeLogo() {
    this.imageHasError = false;
    this.imgCroppChild.showImg();
    this.imgCroppChild.changeImage();
  }

  onImageHasBeenSelected(e) {
    this.imageHasError = false;
    this.changingLogo = true;
  }

  onImageError(message) {
    this.imageHasError = true;
    if (message !== '') {
      this.errorImage = message;
    }
    this.changingLogo = false;
  }

  onRemoveThumbnail(e) {
    this.imageRequestHasError = false;
    this.storeImageUploading = false;
    this.changingLogo = false;
    this.storeImageUploaded = false;

    this.registerForm.patchValue({
      logo: null,
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      s.unsubscribe();
    });
  }

  validateInputClass(
    form: FormGroup,
    fieldName: string,
    submitRegister: boolean
  ): string {
    return this.validateInputService.validateInputClass(
      form,
      fieldName,
      submitRegister
    );
  }

  getUrl() {
    if (this.storeImageUploaded) {
      return this.mediaUrlBase + '/' + environment.prefixBucketImages + '/' + this.storeImageUrl;
    } else {
      return this.mediaUrlBase + this.storeImageUrl;
    }
  }

  async onImageSaved($event) {
    this.storeImageUploading = true;
    const imagePath = await this.imageSrv.saveImageInAmplify('store-logo-', this.registerForm.controls.logo.value);
    if (imagePath) {
      this.registerForm.patchValue({
        logo: imagePath,
      });
      this.storeImageUrl = imagePath;
      this.storeImageUploading = false;
      this.storeImageUploaded = true;
      this.storeImageUploadSuccess.next(true);
    } else {
      this.onRemoveThumbnail(null);
      this.registerForm.patchValue({
        logo: null,
      });
      this.storeImageUploading = false;
      this.imageRequestHasError = true;
      this.submitting = false;
      this.storeImageUploaded = false;
      this.storeImageUploadSuccess.next(false);
    }
  }

  handleDataAddress(event) {
    const address = event[0];
    this.registerForm.get('latitude').patchValue(address.latitude);
    this.registerForm.get('longitude').patchValue(address.longitude);
    this.registerForm.get('city').patchValue(address.city);
    if (!address.city || address.city === '') {
      this.registerForm.controls.city.enable();
      this.cityDisabled = false;
      this.registerForm.controls.city.setErrors({'required': true});
    } else {
      this.registerForm.controls.city.disable();
      this.cityDisabled = true;
    }
  }
}
