<fieldset 
  class="fieldset-input {{customClass}}"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': onFocus,
    'filled': onFilled,
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly,
    'success': success,
    'validate': validate,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
>
  <div class="field__input">
    <label class="label" for="input">{{label}}</label>
    <p-inputMask
      class="input"
      formControlName="{{ controlName }}"
      [mask]="mask"
      (onFocus)="onFocusInput()"
      (onBlur)="onBlurInput()"
      (onComplete)="onComplete()"
    ></p-inputMask>
    <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && shouldShowErrors()"></i>
    <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
    <i class="fa-light fa-spinner-third spinner-icon" *ngIf="validate"></i>
  </div>
  <p class="hint" *ngIf="hint && shouldShowErrors()">
    <span *ngFor="let error of listOfErrors()">{{error}}</span>
  </p>
</fieldset>