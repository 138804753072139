<fieldset
  class="fieldset-input {{customClass}}"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': onFocus,
    'filled': filledInput(),
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly,
    'success': success,
    'validate': validate,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop
  }"
>
  <div class="field__input">
    <label class="label" for="input">{{label}}</label>
    <textarea
      (focus)="onFocusInput()"
      (blur)="onBlurInput()"
      id="description"
      class="input"
      type="text"
      [rows]="5"
      formControlName="{{controlName}}"
      autoResize="false"
      pInputTextarea
      [maxlength]="maxlength"
    ></textarea>
    <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && shouldShowErrors()"></i>
    <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
    <i class="fa-light fa-spinner-third spinner-icon" *ngIf="validate"></i>
  </div>
  <p class="hint" *ngIf="hint && shouldShowErrors()">
    <span *ngFor="let error of listOfErrors()">{{error}}</span>
  </p>

  <div *ngIf="showCounter && (inputField.valid || inputField.untouched)" class="field__item--info">
    <span>{{ inputField.value ? inputField.value.length : 0 }}/{{ maxCounter }}</span>
  </div>
</fieldset>
