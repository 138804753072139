<div class="select-image-container">
  <input
    id="fileInput"
    [ngClass]="{ hidden: hide }"
    #myInput
    type="file"
    [disabled]="isDisabled"
    class="choose-file"
    (change)="fileChangeEvent($event)"
    [ngModel]="archivo"
    accept=".jpg,.jpeg,.png,.webp"
    placeholder="Agregar logo"
    id="chooseImage" />

  <!--<div *ngIf="onlyShowImageName; else showImg">{{ originalFileName }}</div>-->
  <ng-template #showImg>
    <div class="container-image" *ngIf="!hideImgContainer && showImageFinal">
      <img *ngIf="croppedImage" class="thumbnail" [src]="croppedImage" />
    </div>

    <div>
      <button
        pButton
        *ngIf="!iconButtons && croppedImage && !hideImgContainer && !hideTrash && !imageHasBeenUpdated"
        class="btn btn__secondary btn__secondary--red btn__size--sm"
        (click)="removeThumbnail()"
        icon="fa-light fa-trash-can"
        label="{{ cancelBtn }}"></button>
      <button
        pButton
        iconOnly
        icon="fa-light fa-trash-alt fa-lg"
        class="margin-left-16 text-primary"
        *ngIf="iconButtons && croppedImage && !hideImgContainer && !hideTrash"
        (click)="removeThumbnail()"></button>
    </div>
  </ng-template>
</div>

<p-dialog
  [(visible)]="showImageCropper"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  appendTo="body"
  [maskStyleClass]="'bid-modal bid-moda__cropimage'">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <p class="bid-modal__header--title">Seleccione parte de la imagen</p>
  </ng-template>

  <ng-template pTemplate="content" class="bid-modal__content">
    <div class="field__group">
      <image-cropper
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [aspectRatio]="config.aspectRatio"
        [cropperMinWidth]="config.cropperMinWidth"
        [cropperMaxWidth]="config.cropperMaxWidth"
        [cropperMinHeight]="config.cropperMinHeight"
        [cropperMaxHeight]="config.cropperMaxHeight"
        [resizeToWidth]="config.resizeToWidth"
        [resizeToHeight]="config.resizeToHeight"
        height="500"
        [format]="formatSaveImg"
        [imageQuality]="imgQuality"
        (loadImageFailed)="loadImageFailed()"
        (imageCropped)="imageCropped($event)"
        class="image-logo"></image-cropper>
    </div>
  </ng-template>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button
      pButton
      (click)="selectPartImage()"
      class="btn btn__primary btn--one"
      label="Guardar"></button>
  </ng-template>
</p-dialog>
