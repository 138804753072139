import { partitionArray } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  constructor() { }

  isPasswordValid(passwordField: AbstractControl, confirmPasswordField: AbstractControl) {
    if (passwordField.value !== confirmPasswordField.value) {
      return false;
    }

    return true;
  }

  //CUSTOM VALIDATOR FOR REPEAT PASSWORD MATCH
  passwordsMatchRepeat(passwordField: AbstractControl) {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value &&
      control.value !== passwordField.value
        ? {passwordsMatchRepeat: true}
        : null;
    };
  }
}
