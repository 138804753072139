<div class="container-main background-home">
  <div class="container-card">
    <img src="../../../assets/images/maintenance_icon.png" class="image-maintenance" alt="maintenance-icon-bid"/>
    <span class="title">Estamos haciendo mantenimiento</span>
    <span class="sub-title">
        <span>En breve podrás volver a utilizar nuestros servicios.</span><br>
        <span>¡Gracias por tu paciencia!</span>
      </span>
  </div>
</div>

