import {Router} from '@angular/router';
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';

import {AuthService} from 'src/app/services/auth.service';
import {ConfigurationService} from 'src/app/pages/configurations/services/configuration.service';
import {FcmService} from 'src/app/services/fcm.service';
import {LateralMenuService} from 'src/app/services/lateral-menu.service';
import {LocationStrategy} from '@angular/common';
import {NotificationsData} from '../../models/NotificationsData';
import {StoreData} from 'src/app/core/models/auth/StoreData';
import {Observable, Subscription} from 'rxjs';
import {environment} from 'src/environments/environment';
import {MenuService} from 'src/app/services/menu.service';

@Component({
  selector: 'app-lateral-menu',
  templateUrl: './lateral-menu.component.html',
  styleUrls: ['./lateral-menu.component.scss'],
})
export class LateralMenuComponent implements OnInit, OnDestroy {
  @Input() events: Observable<void>;
  storeData: StoreData;
  apiUrl: string;
  element: HTMLImageElement;
  urlImage: string;
  items: MenuItem[];
  notificationsCounter: NotificationsData = null;
  subs: Subscription[] = [];
  openLateralMenu = false;
  active = false;
  itemEvent = false;
  itemRealTime = false;
  itemMenu = false;
  display = false;
  isEventsRout = false;
  isHomeRout = false;
  isMenuRout = false;
  isMenuProductsRout = false;
  isMenuCategoriesRout = false;
  isMenuAttributesRout = false;
  isRealTimeRout = false;
  isRealTimeTableRout = false;
  isRealTimeBookingsRout = false;
  isRealTimeWaitingListRout = false;
  isPromotionsRout = false;
  isConfigRout = false;
  subscription: Subscription;
  isMenuLocked = false;

  constructor(
    private authService: AuthService,
    private fcmSrv: FcmService,
    private url: LocationStrategy,
    private configurationService: ConfigurationService,
    private router: Router,
    private lateralMenuSrv: LateralMenuService,
    private menuService: MenuService
  ) {
  }

  ngOnInit(): void {
    this.subs.push(
      this.authService.user.subscribe({
        next: (user: StoreData) => {
          this.storeData = user;
          this.urlImage = environment.mediaUrl + this.storeData.logoUrl;
          const logged = localStorage.getItem('isLoggedIn');
          if (logged) {
            this.checkNotifications();
            this.fcmSrv.notificationsSubject$.subscribe({
              next: () => {
                this.checkNotifications();
              }
            });
          }
        }
      })
    );

    this.itemEvents();
    this.itemControl();
    this.itemMenuControl();
    this.subscription = this.events.subscribe({
      next: () => {
        this.detectCurrentURL();
      }
    });
    this.detectCurrentURL();

    this.fcmSrv.notificationsUpdate$.subscribe({
      next: () => {
        this.checkNotifications();
      }
    });

    if (window.innerWidth <= environment.limitLockSidebar) {
      this.menuService.lockMenu();
    }

    this.menuService.menuLocked$.subscribe({
      next: (res: boolean) => {
        if (res) {
          this.isMenuLocked = res;
          this.lateralMenuSrv.closeMenu();
        }
      }
    });
  }

  countRealTimeNotifications() {
    return this.notificationsCounter.unreadTableNotifications +
      this.notificationsCounter.unreadReservationsNotification +
      this.notificationsCounter.unreadWaitingListNotification;
  }

  detectCurrentURL() {
    this.isEventsRout =
      this.router.routerState.snapshot.url.indexOf('/event/**') !== -1
      || this.router.routerState.snapshot.url.indexOf('/events/**') !== -1
      || this.router.routerState.snapshot.url.indexOf('/events/list') !== -1
      || this.router.routerState.snapshot.url.indexOf('/access-control') !== -1;
    this.isHomeRout =
      this.router.routerState.snapshot.url.indexOf('/stats') !== -1
      || this.router.routerState.snapshot.url.indexOf('/stats/event') !== -1
      || this.router.routerState.snapshot.url.indexOf('/stats/event/**') !== -1;
    this.isMenuRout =
      this.router.routerState.snapshot.url.indexOf('/menu/') !== -1;
    this.isMenuProductsRout =
      this.router.routerState.snapshot.url.indexOf('/menu/productos') !== -1;
    this.isMenuCategoriesRout =
      this.router.routerState.snapshot.url.indexOf('/menu/categories') !== -1;
    this.isMenuAttributesRout =
      this.router.routerState.snapshot.url.indexOf('/menu/atributos') !== -1;
    this.isRealTimeRout =
      this.router.routerState.snapshot.url.indexOf('/real-time/') !== -1;
    this.isRealTimeTableRout =
      this.router.routerState.snapshot.url.indexOf('/real-time/pedidos-mesa') !== -1;
    this.isRealTimeBookingsRout =
      this.router.routerState.snapshot.url.indexOf('/real-time/reservas') !== -1;
    this.isRealTimeWaitingListRout =
      this.router.routerState.snapshot.url.indexOf('/real-time/lista-espera') !== -1;
    this.isPromotionsRout =
      this.router.routerState.snapshot.url.indexOf('/promotions') !== -1;
    this.isConfigRout =
      this.router.routerState.snapshot.url.indexOf('/configuraciones') !== -1;
  }

  checkNotifications() {
    this.fcmSrv.getUnreadNotifications().subscribe({
      next: (res: NotificationsData) => {
        this.notificationsCounter = res;
      },
      error: () => {
      }
    });
  }

  openAsideMenu() {
    if (this.openLateralMenu === false && !this.isMenuLocked) {
      this.openLateralMenu = true;
      this.lateralMenuSrv.openMenu();
    } else {
      this.openLateralMenu = false;
      this.lateralMenuSrv.closeMenu();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }

  itemEvents() {
    this.itemEvent = this.url.path() === '/events' ||
      this.url.path() === '/events/list' ||
      this.url.path() === '/events/access-control';
  }

  itemControl() {
    this.itemRealTime = this.url.path() === '/real-time/pedidos-mesa' ||
      this.url.path() === '/real-time/reservas' ||
      this.url.path() === '/real-time/lista-espera';
  }

  itemMenuControl() {
    this.itemMenu = this.url.path() === '/menu/productos' ||
      this.url.path() === '/menu/categories' ||
      this.url.path() === '/menu/atributos';
  }

  openLogout() {
    this.display = true;
  }

  hideDialog() {
    this.display = false;
  }

  expandMenu(element, firstItem, path) {
    const el = document.getElementsByClassName(element)[0];
    const elements = document.getElementsByClassName('show');
    const tabElements = document.getElementsByClassName('item-tab');
    const activeItems = document.getElementsByClassName('is-active');
    const firstItemTab = document.getElementsByClassName(firstItem)[0];

    this.removeClass(elements, 'show');
    this.removeClass(tabElements, 'show');
    this.removeClass(tabElements, 'is-active');
    this.removeClass(activeItems, 'is-active');

    if (el && firstItemTab) {
      el.classList.add('show');
      firstItemTab.classList.add('is-active');
    }

    this.router.navigate([path]);
  }

  tab(element, path) {
    const el = document.getElementsByClassName(element)[0];
    const elements = document.getElementsByClassName('is-active');

    this.removeClass(elements, 'is-active');

    if (el) {
      el.classList.add('is-active');
    }

    this.router.navigate([path]);
  }

  // tslint:disable-next-line:variable-name
  removeClass(array, string) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      array[i].classList.remove(string);
    }
  }

  logout() {
    this.display = false;
    this.lateralMenuSrv.closeMenu();
    if (this.configurationService.getCanLogout()) {
      localStorage.clear();
    }
    this.router.navigate(['/login']);
  }
}


