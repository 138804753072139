import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';

import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ErrorService} from 'src/app/services/error.service';

@Component({
  selector: 'bid-input',
  templateUrl: './bid-input.component.html',
  styleUrls: ['./bid-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BidInputComponent
    }
  ]
})
export class BidInputComponent implements OnInit, AfterViewInit {
  listOfErrors = [];

  @ViewChild('input') input: ElementRef;
  @Input() label: string;
  @Input() controlName: string;

  @Input() onFocus = false;
  @Input() onError = false;
  @Input() readonly = false;

  @Input() success = false;
  @Input() successIcon = false;
  @Input() type = 'text';
  @Input() name = '';
  @Input() hint = true;
  @Input() page = 'generic';
  @Input() disabled = false;
  @Input() showInfoAddress = false;

  @Input() formHasBeenSubmitted = false;
  @Input() preventSpace = false;
  @Input() customClass = '';
  @Input() withIcon = true;
  @Input() onFilled = false;
  @Input() max: number;
  @Input() min = 0;
  @Input() customError = false;
  @Input() validate = false;
  @Input() bgLightMobile = false;
  @Input() bgLightDesktop = false;
  @Input() large = false;
  @Input() largeMobile = false;
  @Input() largeDesktop = false;
  // @Input() formControlName;
  @Input() parentForm: FormGroup;

  // configuration conter
  @Input() showCounter = false;
  @Input() maxCounter = 50;
  @Input() isBidAccessUsername = false;

  helperText = '';
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';
  @Input() required = false;
  @Input() sendAsNumber = false;
  @Input() toUpperCase = false;

  //Custom Hint is different to customnKey becase Custom Hint doesn't hear to formHasBeenSubmitted
  @Input() customHint = '';

  @Output() keyUpChange = new EventEmitter();
  @Output() focusOut = new EventEmitter();

  constructor(private renderer: Renderer2, private errorService: ErrorService) {
  }

  ngOnInit(): void {
    this.inputField.valueChanges.subscribe(() => {
      this.filledInput();
    });

    if (this.disabled) {
      this.inputField.disable();
    }
  }

  get inputField() {
    return this.parentForm.get(this.controlName);
  }

  ngAfterViewInit() {
    this.filledInput();
    this.shouldShowErrors();
    this.checkIsFocus();

    if (this.max) {
      this.renderer.setAttribute(this.input.nativeElement, 'maxlength', this.max.toString());
    }
    if (this.min) {
      this.renderer.setAttribute(this.input.nativeElement, 'minlength', this.min.toString());
    }
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  onFocusOut() {
    this.focusOut.emit();
    if (this.sendAsNumber) {
      this.inputField.patchValue(parseInt(this.inputField.value));
    }
  }

  onKeyUpInput(e) {

    if (this.toUpperCase) {
      this.inputField.patchValue(this.inputField.value.toUpperCase());
    }

    this.keyUpChange.emit(e);
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
      setTimeout(() => this.input.nativeElement.focus(), 500);
    }
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
  }

  filledInput() {
    if (this.inputField.value !== '' && this.inputField.value !== ' ' && this.inputField.value !== null) {
      this.onFilled = true;
      this.onError = false;
    } else {
      this.onFilled = false;
    }
    return this.onFilled;
  }

  shouldShowErrors(): Array<string> {
    this.listOfErrors = this.errorService.shouldShowErrors(
      this.inputField, this.formHasBeenSubmitted, this.page, this.customError, this.customKey);
    return this.listOfErrors;
  }
}
