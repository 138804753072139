import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorCuitService {

  constructor() {
  }

  validCuit(cuit) {
    if (cuit.toString().length !== 11) {
      return false;
    }

    let accumulated = 0;
    const digits = cuit.toString().split('');
    const digit = digits.pop();

    for (let i = 0; i < digits.length; i++) {
      accumulated += digits[9 - i] * (2 + (i % 6));
    }

    let verification = 11 - (accumulated % 11);
    if (verification === 11) {
      verification = 0;
    } else if (verification === 10) {
      verification = 9;
    }
    return digit === verification.toString();
  }
}
