import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  async ngOnInit() {
    this.checkMaintenance();
    setInterval(() => {
      this.checkMaintenance();
    }, 60000);
  }

  checkMaintenance() {
    const isInMaintenanceMode = environment.isUnderMaintenance;
    if (!isInMaintenanceMode) {
      this.router.navigate(['']);
    }
  }

}
