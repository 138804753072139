<div class="new-alert" *ngIf="unreadNotifications.length > 0 && unreadNotifications[0].type === 'TABLE'">
  <div class="pt-2 pb-2 pl-2 pr-2 new-alert-left-container">
    <div #newOrder class="btn btn__size--xs alert-type">
      <i class="fa-solid fa-bell"></i> NUEVO PEDIDO
    </div>

    <div class="notification-type">
      <div class="data">
        <img class="mr-1" src="../../../../assets/images/union.png" alt="">
        <span>En mesa</span>
      </div>
    </div>

    <div>
      <button pButton label="Ver pedido" class="p-button p-component btn btn__secondary btn__size--xxxs"
              (click)="redirectTo()"></button>
    </div>
  </div>
  <div class="count-nofitications">
    <p-badge
      *ngIf="unreadNotifications.length > 0"
      class="custom-badge count-badge"
      [value]="unreadNotifications.length.toString(10)"
    ></p-badge>
    <i class="fa-light fa-bell"></i>
  </div>
</div>

<button #hiddenButtonMusic class="hidden-button-music" (click)="playSound()">hidden button music</button>
