import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {AbstractControl, FormGroup} from '@angular/forms';
import JSONStrings from 'src/app/core/internalization/es.json';

@Component({
  selector: 'bid-textarea',
  templateUrl: './bid-textarea.component.html',
  styleUrls: ['./bid-textarea.component.scss']
})
export class BidTextareaComponent implements OnInit, AfterViewInit {

  @Input() label = 'Campo';
  @Input() required = false;
  @Input() onFocus = false;
  @Input() onError = false;
  @Input() readonly = false;
  @Input() success = false;
  @Input() type = 'text';
  @Input() name = '';
  @Input() hint = true;
  @Input() page = '';
  @Input() capitalize = false;
  @Input() lowerCase = false;
  @Input() uppercase = false;
  @Input() formHasBeenSubmitted = false;
  @Input() preventSpace = false;
  @Input() customClass = '';
  @Input() withIcon = true;
  @Input() onFilled = false;
  @Input() maxlength = 320;
  @Input() minlength = 1;
  @Input() customError = false;
  @Input() validate = false;
  @Input() bgLightMobile = false;
  @Input() bgLightDesktop = false;
  @Input() large = false;
  @Input() largeMobile = false;
  @Input() largeDesktop = false;
  @ViewChild('input') input: ElementRef;

  // CONTROL config
  @Input() controlName: string;
  @Input() parentForm: FormGroup;

  // COUNTER config
  @Input() showCounter = false;
  @Input() maxCounter = this.maxlength;

  helperText = '';

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';

  constructor() {
  }

  get inputField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.shouldShowErrors();
    this.checkIsFocus();
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
      this.input.nativeElement.focus();
    }
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
  }

  filledInput() {
    if (this.inputField.value && this.inputField.value.length > 1 && !this.onFilled) {
      this.onFilled = true;
      this.onError = false;
    }
    return this.onFilled;
  }

  shouldShowErrors(): boolean {
    if (this.inputField != null) {
      if (this.inputField.errors != null) {
        if (this.inputField.invalid && this.formHasBeenSubmitted) {
          this.onError = true;
        }
      }
    } else if (this.customError) {
      this.onError = true;
    } else {
      this.onError = false;
    }
    return this.onError;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.inputField.errors != null) {
      return Object.keys(this.inputField.errors).map((err) => {

        return JSONStrings[this.page][err];
      });
    }
  }
}
