<main class="wrapper">
  <div class="ups">
    <img src="../../../assets/images/img-ups.svg" class="img-ups" alt="Not found"/>
    <div class="ups__content">
      <h1 class="title">Ups..!<br/>algo no salió tan bien...</h1>
      <p class="description">No pudimos encontrar lo que estabas buscando.</p>
      <button pButton label="Volvamos al inicio" class="btn btn__size--md btn__primary" (click)="goHome()"></button>
    </div>
  </div>
</main>
