<div class="container">
  <div class="form__container">
    <div class="form__header">
      <a href="/">
        <img
          src="../../../../assets/images/iso-logo.png"
          class="logo"
          alt="BLACK iD"/>
      </a>
    </div>

    <div class="form__wrapper">
      <div class="form__title">
        <p class="text-gray-1 text-lg lh-24 font-semibold my-0">
          Crea tu cuenta
        </p>
      </div>

      <form class="form" [formGroup]="registerForm" autocomplete="off">
        <bid-dropdown
          [label]="'Seleccioná tu país*'"
          [name]="'country'"
          [page]="'register'"
          [optionLabel]="'name'"
          [optionValue]="'id'"
          [controlName]="'country'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [readonly]="isReadOnly"
          [loading]="searchingCountries"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [options]="countries"
          (data)="countryChange()"></bid-dropdown>

        <bid-input-mask
          *ngIf="countryField && countryField.value === 'ARGENTINA'"
          [label]="'Cuit*'"
          [name]="'taxIdentifier'"
          [page]="'register'"
          [mask]="'99-99999999-9'"
          [controlName]="'taxIdentifier'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input-mask>

        <bid-input-mask
          *ngIf="countryField && countryField.value === 'BRASIL'"
          [label]="'CNPJ*'"
          [name]="'taxIdentifier'"
          [page]="'register'"
          [mask]="'99.999.999/9999-99'"
          [controlName]="'taxIdentifier'"
          [parentForm]="registerForm"
          [readonly]="isReadOnly"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input-mask>

        <bid-input-mask
          *ngIf="countryField && countryField.value === 'ESPAÑA'"
          [label]="'NIF*'"
          [name]="'taxIdentifier'"
          [page]="'register'"
          [mask]="'*********'"
          [controlName]="'taxIdentifier'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input-mask>

        <bid-input-mask
          *ngIf="countryField && countryField.value === 'EEUU'"
          [label]="'TIN*'"
          [name]="'taxIdentifier'"
          [page]="'register'"
          [mask]="'999999999'"
          [controlName]="'taxIdentifier'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input-mask>

        <bid-input
          [label]="'Nombre del comercio*'"
          [type]="'text'"
          [name]="'name'"
          [page]="'register'"
          [controlName]="'name'"
          [parentForm]="registerForm"
          [max]="50"
          [largeMobile]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [customClass]="'margin-bottom-0'"></bid-input>
        <div
          *ngIf="
            !formHasBeenSubmitted ||
            (formHasBeenSubmitted && registerForm.get('name').valid)
          "
          class="field__item--info">
         <span>Máximo {{ maxCommerceNameLength }} caracteres</span>
        </div>

        <!--<div class="text-gray-1 text-base lh-24 margin-top-24">
          <i class="fa-solid fa-location-check"></i>
          Verifique que al seleccionar la dirección, la misma sea valida
          <div
            class="tooltip-cursor"
            pTooltip="Para que la dirección sea válida, la ciudad debe autocompletarse. Si no es así, por favor ingrese otra dirección (calle y altura) o ubicación"
            tooltipPosition="top">
            <i class="fa-light fa-circle-info"></i>
          </div>
        </div>-->

        <bid-input-address-suggested
          [label]="'Dirección*'"
          [type]="'text'"
          [name]="'address'"
          [page]="'register'"
          [controlName]="'address'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [disabled]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          (addressSelected)="handleDataAddress($event)"
          [customClass]="'margin-top-8'"></bid-input-address-suggested>

        <bid-input
          label="Ciudad"
          type="text"
          page="register"
          [controlName]="'city'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [preventSpace]="true"
          [readonly]="cityDisabled"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input>

        <bid-input
          label="Teléfono*"
          type="number"
          page="register"
          [controlName]="'phone'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [preventSpace]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input>

        <bid-input
          label="Email*"
          type="text"
          page="register"
          [customClass]="'margin-bottom-0'"
          [controlName]="'email'"
          [parentForm]="registerForm"
          [largeMobile]="true"
          [preventSpace]="true"
          [readonly]="isReadOnly"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"></bid-input>
        <p class="text-gray-35 font-s-12 lh-16 margin-top-8 margin-bottom-24">
          A este email van a llegar notificaciones sobre los diferentes
          movimientos que puedan realizar los usuarios para con el comercio que
          esta por crear.
        </p>

        <bid-input-password
          [label]="'Contraseña'"
          [page]="'password'"
          [controlName]="'password'"
          [parentForm]="registerForm"
          [readonly]="isReadOnly"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [customClass]="'margin-bottom-24'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [passwordMatch]="passwordMatch"
          [showLabelRequirement]="true"></bid-input-password>

        <bid-input-password
          [label]="'Repetí la contraseña*'"
          [page]="'password'"
          [controlName]="'confirmPassword'"
          [parentForm]="registerForm"
          [readonly]="isReadOnly"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [customClass]="'margin-bottom-24'"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [passwordMatch]="passwordMatch"></bid-input-password>

        <div class="w-full margin-top-32">
          <div
            class="p-d-flex"
            *ngIf="
              !storeImageUploading &&
              !imageRequestHasError &&
              !storeImageUploaded
            ">
            <i
              class="fas fa-cloud-arrow-up text-gray-1 text-sm mr-2 p-ai-center pt-1"></i>
            <p class="text-base lh-24 font-semibold text-gray-1">
              Subí el logo de tu comercio*
            </p>
          </div>
          <div
            class="field__item"
            [ngClass]="
              validateInputClass(registerForm, 'logo', formHasBeenSubmitted)
            ">
            <div
              *ngIf="imageHasError && errorImage != ''"
              class="field__item--error mb-4">
              <span class="icon-warning mr-2"></span>{{ errorImage }}
            </div>
            <!-- //*ngIf="!changingLogo" -->
            <img id="image-edited" *ngIf="storeImageUrl !== null" src="{{ getUrl() }}"
            width="50%"
            class="p-shadow-4 image-logo" alt="Imagen evento"/>
            <button
              *ngIf="!storeImageUploading && !imageRequestHasError"
              pButton
              class="btn btn__secondary btn__size--sm"
              label="Seleccionar archivo"
              (click)="changeLogo()"></button>
            <app-show-errors
              [page]="'register'"
              [ctrl]="registerForm.get('logo') | formControl"
              [formHasBeenSubmitted]="formHasBeenSubmitted"
              [withoutIcon]="true"></app-show-errors>
            <app-load-img-cropp
              [hide]="true"
              formControlName="logo"
              [title]=""
              [cancelBtn]="'Remover imagen'"
              [config]="croppConfig"
              [showToastError]="false"
              (removeEmit)="onRemoveThumbnail($event)"
              (imageHasBeenSelected)="onImageHasBeenSelected($event)"
              (imageHasBeenSelectedAndSave)="onImageSaved($event)"
              (imageError)="onImageError($event)"></app-load-img-cropp>
          </div>

          <div
            *ngIf="storeImageUploading"
            class="text-gray-1 font-s-14 lh-24 mr-2 mb-4">
            Cargando imagen
            <span class="fa-light fa-spinner-third spinner-icon ml-1"></span>
          </div>

          <div
            class="error__register p-mt-4 p-mb-4"
            *ngIf="imageRequestHasError">
            <div class="icon__warning">
              <i
                class="fa-solid fa-triangle-exclamation fa-lg text-error p-mr-2"></i>
            </div>
            <span
            >No fue posible cargar la imagen del comercio, vuelva a intentarlo
              nuevamente.</span
            >
            <div class="close">
              <i
                class="fa-light fa-xmark fa-xs text-gray-1"
                (click)="errorRequest = ''"></i>
            </div>
          </div>
        </div>

        <div
          class="field__item checkbox__legal"
          [ngClass]="
            validateInputClass(
              registerForm,
              'acceptTerms',
              formHasBeenSubmitted
            )
          ">
          <p-checkbox
            formControlName="acceptTerms"
            binary="true"
            required></p-checkbox>
          <span class="label-terms p-ml-2"
          >Acepto los
            <a class="a-terms text-link" (click)="goToLink('terms')"
            >términos y condiciones</a
            >
            y las
            <a class="a-terms text-link" (click)="goToLink('policies')"
            >políticas de privacidad.</a
            >
          </span>
        </div>
        <app-show-errors
          [page]="'register'"
          [ctrl]="registerForm.get('acceptTerms') | formControl"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [withoutIcon]="true"></app-show-errors>

        <div
          class="error__register p-mt-4 p-mb-4"
          *ngIf="formHasBeenSubmitted && errorRequest != ''">
          <div class="icon__warning">
            <i
              class="fa-solid fa-triangle-exclamation fa-lg text-error p-mr-2"></i>
          </div>
          <span>{{ errorRequest }}</span>
          <div class="close">
            <i
              class="fa-light fa-xmark fa-xs text-gray-1"
              (click)="errorRequest = ''"></i>
          </div>
        </div>

        <div class="w-100 p-d-flex p-jc-center p-ai-center margin-top-32">
          <button
            pButton
            (click)="confirmUserDetails()"
            [disabled]="storeImageUploading || imageRequestHasError"
            class="btn btn__primary"
            label="Registrarme"></button>
        </div>
      </form>
    </div>
  </div>
  <div class="create_account">
    <p class="text-gray-35 mb-2 text-center">
      ¿Ya tenés una cuenta?
      <a
        [routerLink]="['/login']"
        class="font-semibold text-link cursor-pointer"
      >Iniciar sesión</a
      >
    </p>
  </div>

  <p-dialog
    [(visible)]="displayTerms"
    [draggable]="false"
    [resizable]="false"
    [modal]="true"
    class="bid-modal">
    <ng-template pTemplate="header" class="bid-modal__header">
      <p class="bid-modal__header--title">{{ textHeaderTerms }}</p>
    </ng-template>
    <ng-template pTemplate="content" class="bid-modal__content">
      <div class="field__group">
        <p [innerHTML]="contentTerms"></p>
        <span class="text-update"
        >Última actualización: {{ lastUpdate | date: 'HH:mm' }}</span
        >
      </div>
    </ng-template>
    <ng-template pTemplate="footer" class="bid-modal__footer">
      <button
        pButton
        class="btn btn__primary btn--one"
        (click)="displayTerms = false"
        label="Aceptar términos y condiciones y políticas de privacidad"></button>
    </ng-template>
  </p-dialog>

  <p-dialog
    [(visible)]="confirmDialog"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    class="bid-modal">
    <ng-template pTemplate="header" class="bid-modal__header">
      <div class="filling-space"></div>
      <p class="bid-modal__header--title">Confirmá tus datos</p>
    </ng-template>
    <ng-template pTemplate="content" class="bid-modal__content">
      <div class="field__group">
        <div class="confirm-data-container">
          <div *ngFor="let detail of userDetails">
            <div
              class="p-grid"
              *ngIf="displayProperty(detail.key)"
              [ngClass]="{ 'row-data': detail.key !== 'city' }">
              <p class="p-col-6 text-gray-1 text-base lh-24 my-0">
                {{ detail.key | registerKeys }}:
              </p>
              <p class="p-col-6 text-gray-1 text-base lh-24 my-0">
                {{
                detail.key === 'address'
                  ? detail.value.split(',')[0]
                  : (detail.value | hyphenate)
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template pTemplate="footer" class="bid-modal__footer">
      <button
        pButton
        (click)="confirmDialog = false"
        class="btn btn__secondary"
        label="Modificar"></button>
      <button
        pButton
        (click)="submitRegister()"
        [disabled]="submitting"
        class="btn btn__primary"
        label="Confirmar"></button>
    </ng-template>
  </p-dialog>
</div>
