import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import jwt_decode from 'jwt-decode';
import {ConfirmationService} from 'primeng/api';
import {Router} from '@angular/router';
import {ConfigurationService} from 'src/app/pages/configurations/services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  jwtPrev: string;
  jwt: string;
  lo7161: string = '843d1a72f8e9d8f1d51785b5ccf1c69c589430ab';

  constructor(
    private confirmationService: ConfirmationService,
    private router: Router,
    private configurationService: ConfigurationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.jwt = localStorage.getItem('BE-JWT');
    const logged = localStorage.getItem('isLoggedIn');
    let request = req;

    if (this.jwt) {
      const decoded = jwt_decode(this.jwt);
      const exp = decoded['exp'];
      if (Date.now() >= exp * 1000) {
        this.confirm();
        // alert('Su sesion ha expirado. Por favor vuelva a iniciar sesion.');
      } else if (this.jwtPrev !== null && this.jwtPrev !== undefined && this.jwt !== this.jwtPrev && logged) {
        this.jwt = 'blackid';
        this.confirmReload();
      } else {
        this.jwtPrev = this.jwt;
      }
      request = req.clone({
        setHeaders: {
          authorization: this.jwt,
          'x-o7161': this.lo7161
        }
      });
    } else {
      this.jwtPrev = null;
      request = req.clone({
        setHeaders: {
          'x-o7161': this.lo7161
        }
      });
    }

    return next.handle(request);
  }

  confirm() {
    this.confirmationService.confirm({
      message: `<span class="font-poppins text-xl font-semibold lh-26 text-gray-1 mb-2">Tu sesión expiró</span><br>Volvé a iniciar sesión para continuar operando con Black id Backoffice.`,
      icon: 'fal fa-info-circle text-12xl text-info pt-2 mb-6',
      rejectVisible: false,
      key: 'jwtExpired',
      closeOnEscape: false,
      acceptButtonStyleClass: 'btn btn__primary',
      rejectIcon: 'none',
      acceptIcon: 'none',
      accept: () => {
        if (this.configurationService.getCanLogout()) {
          localStorage.clear();
        }
        this.router.navigate(['/login']);
      },
      reject: () => {
        this.confirm();
      },
    });
  }

  confirmReload() {
    this.confirmationService.confirm({
      message: `<span class="font-poppins text-xl font-semibold lh-26 text-gray-1 mb-2">Verificá tus pestañas y actualizá</span><br>Debes actualizar para continuar. Finalizá las acciones en progreso en tus otras pestañas antes de actualizar. Todos los procesos no terminados se perderán.`,
      rejectVisible: false,
      key: 'changeAccount',
      icon: 'fal fa-warning text-12xl text-warning mb-6',
      rejectIcon: 'none',
      acceptIcon: 'none',
      closeOnEscape: false,
      acceptButtonStyleClass: 'btn btn__primary',
      accept: () => {
        const user = JSON.parse(localStorage.getItem('storeData'));
        localStorage.setItem('BE-JWT', user.jwt);
        location.reload();
      }
    });
  }
}

