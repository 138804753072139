import {Injectable} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class LateralMenuService {

  lateralMenuSubject = new Subject();
  currentState = false;

  constructor() {
    this.closeMenu();
  }

  getCurrentState() {
    return this.currentState;
  }

  openMenu() {
    this.lateralMenuSubject.next(true);
    this.currentState = true;
  }

  closeMenu() {
    this.lateralMenuSubject.next(false);
    this.currentState = false;
  }
}
