import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import JSONStrings from 'src/app/core/internalization/es.json';

@Component({
  selector: 'bid-input-mask',
  templateUrl: './bid-input-mask.component.html',
  styleUrls: ['./bid-input-mask.component.scss']
})
export class BidInputMaskComponent implements OnInit {
  
  @Input() label: string = "Campo";
  @Input() required: boolean = false;
  @Input() onFocus: boolean = false;
  @Input() onError: boolean = false;
  @Input() readonly: boolean = false;
  @Input() success: boolean = false;
  @Input() type: string = "text";
  @Input() name: string = "";
  @Input() hint: boolean = true;
  @Input() page: string = '';
  @Input() capitalize: boolean = false;
  @Input() lowerCase: boolean = false;
  @Input() uppercase: boolean = false;
  @Input() formHasBeenSubmitted: boolean = false;
  @Input() preventSpace: boolean = false;
  @Input() customClass: string = '';
  @Input() withIcon: boolean = true;
  @Output() data = new EventEmitter<string>();
  @Input() onFilled: boolean = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() customError: boolean = false;
  @Input() validate: boolean = false;
  @Input() bgLightMobile: boolean = false;
  @Input() bgLightDesktop: boolean = false;
  @Input() large: boolean = false;
  @Input() largeMobile: boolean = false;
  @Input() largeDesktop: boolean = false;
  @Input() ctrl: FormControl;
  @ViewChild('input') input: ElementRef;
  @Input() mask: any;
  helperText: string = "";
  value: string;
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';


  @Input() controlName: string;
  @Input() parentForm: FormGroup;

  constructor() {
  }

  get inputField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.filledInput();
    this.shouldShowErrors();
    this.checkIsFocus();
  }
  
  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
      this.input.nativeElement.focus();
    }
  }

  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  onBlurInput() {
    this.onFocus = false;
    this.onError = false;
    
    this.filledInput();
  }

  onComplete() {
    this.data.emit(this.inputField.value);
  }

  filledInput() {
    if (this.inputField.value?.length > 1 && !this.onFilled) {
      this.onFilled = true;
      this.onError = false;
    }
    return this.onFilled;
  }
  
  shouldShowErrors(): boolean {
    if (this.inputField.errors != null) {
      if (this.inputField.invalid && this.formHasBeenSubmitted) {
        this.onError = true;
      }
    } else if (this.customError) {
      this.onError = true;
    } else {
      this.onError = false;
    }
    return this.onError;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.inputField.errors != null) {
      return Object.keys(this.inputField.errors).map((err) => {
        
        return JSONStrings[this.page][err];
      });
    }
  }
}
