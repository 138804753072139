import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-mobile-access',
  templateUrl: './mobile-access.component.html',
  styleUrls: ['./mobile-access.component.scss']
})
export class MobileAccessComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
