//   A - Sociedades Anónimas
//   B - Sociedades de responsabilidad limitada
//   C - Sociedades colectivas
//   D - Sociedades comanditarias
//   E - Comunidades de bienes
//   F - Sociedades cooperativas
//   G - Asociaciones y otros tipos no definidos
//   H - Comunidades de propietarios
//   J - Sociedades civiles, con o sin personalidad jurídica
//   K - Españoles menores de 14 años
//   L - Españoles residentes en el extranjero sin DNI
//   M - NIF que otorga la Agencia Tributaria a extranjeros que no tienen NIE
//   N - Entidades extranjeras
//   P - Corporaciones locales
//   Q - Organismos autónomos
//   R - Congregaciones e instituciones religiosas
//   S - Organos de la administración
//   U - Uniones Temporales de Empresas
//   V - Otros tipos no definidos en el resto de claves
//   W - Establecimientos permanentes de entidades no residentes en España
//   X - Extranjeros identificados por la Policía con un número de identidad de extranjero, NIE, asignado hasta el 15 de julio de 2008
// tslint:disable-next-line:max-line-length
//   Y - Extranjeros identificados por la Policía con un NIE, asignado desde el 16 de julio de 2008 (Orden INT/2058/2008, BOE del 15 de julio)
//   Z - Letra reservada para cuando se agoten los 'Y' para Extranjeros identificados por la Policía con un NIE
//
//   La ultima cifra es el dígito de control, que puede ser o bien un número o bien
//   una letra, en función del tipo de sociedad.
//   A las categorias P (Ayuntamientos) y X (Extranjeros) les corresponde una letra
//   en lugar de un número.
//
//   El dígito de control se calcula con las 7 cifras restantes del CIF (quitando la
//   primera y la ultima), con el siguiente algoritmo:
//
// - CIF: A58818501
// - Quitamos la primera y la ultima cifra:
//     5881850
// - Sumamos las cifras pares:
//     Suma = 8 + 1 + 5 = 14
//     - Ahora sumamos cada cifra impar multiplicada por dos, y sumamos las cifras del
//   resultado:
//     5 * 2 = 10 ==> 1 + 0 = 1
//   8 * 2 = 16 ==> 1 + 6 = 7
//   8 * 2 = 16 ==> 1 + 6 = 7
//   0 * 2 = 0 ==> 0
// - y volvemos a sumar esos resultados a la suma anterior:
//     Suma=Suma+1+7+7+0;
// - Al final de este proceso, tenemos que Suma=29, pues bien, nos quedamos con la
//   cifra de las unidades (9)
// - Restamos esta cifra de las unidades de 10, dándonos un 1, que es el código de
//   control para todos los tipos de sociedades exceptuando la X que se verifica
//   como un DNI.
// - Para las sociedades K, P, Q y S habria que sumar un 64 al digito de control que
//   hemos calculado para hallar el ASCII de la letra de control:
//     Chr(64+(10-(Suma mod 10)))

import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorNifService {

  constructor() {
  }

  /*
   * Tiene que recibir el cif sin espacios ni guiones
   */
  public validateNIF(cif) {
    // Quitamos el primer caracter y el ultimo dígito
    const valueCif = cif.substr(1, cif.length - 2);

    let suma = 0;

    // Sumamos las cifras pares de la cadena
    for (let i = 1; i < valueCif.length; i = i + 2) {
      suma = suma + parseInt(valueCif.substr(i, 1), 10);
    }

    let suma2 = 0;

    // Sumamos las cifras impares de la cadena
    let result;
    for (let i = 0; i < valueCif.length; i = i + 2) {
      result = parseInt(valueCif.substr(i, 1), 10) * 2;
      if (String(result).length === 1) {
        // Un solo caracter
        suma2 = suma2 + parseInt(result, 10);
      } else {
        // Dos caracteres. Los sumamos...
        suma2 = suma2 + parseInt(String(result).substr(0, 1), 10) + parseInt(String(result).substr(1, 1), 10);
      }
    }

    // Sumamos las dos sumas que hemos realizado
    suma = suma + suma2;

    let unidad = Number(String(suma).substr(1, 1));
    unidad = 10 - unidad;

    // tslint:disable-next-line:prefer-const
    const primerCaracter = cif.substr(0, 1).toUpperCase();

    if (primerCaracter.match(/^[FJKNPQRSUVW]$/)) {
      // Empieza por .... Comparamos la ultima letra
      if (String.fromCharCode(64 + unidad).toUpperCase() === cif.substr(cif.length - 1, 1).toUpperCase()) {
        return true;
      }
    } else if (primerCaracter.match(/^[XYZ]$/)) {
      // Se valida como un dni
      let newcif;
      if (primerCaracter === 'X') {
        newcif = cif.substr(1);
      } else if (primerCaracter === 'Y') {
        newcif = '1' + cif.substr(1);
      } else if (primerCaracter === 'Z') {
        newcif = '2' + cif.substr(1);
      }
      return this.validateDNI(newcif);
    } else if (primerCaracter.match(/^[ABCDEFGHLM]$/)) {
      // Se revisa que el ultimo valor coincida con el calculo
      if (unidad === 10) {
        unidad = 0;
      }
      if (cif.substr(cif.length - 1, 1) === String(unidad)) {
        return true;
      }
    } else {
      // Se valida como un dni
      return this.validateDNI(cif);
    }
    return false;
  }

  /*
   * Tiene que recibir el dni sin espacios ni guiones
   * Esta funcion es llamada
   */
  public validateDNI(dni) {
    const lockup = 'TRWAGMYFPDXBNJZSQVHLCKE';
    const valueDni = dni.substr(0, dni.length - 1);
    const letra = dni.substr(dni.length - 1, 1).toUpperCase();

    return lockup.charAt(valueDni % 23) === letra;
  }
}
