/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import {Amplify} from "aws-amplify";
import {environment} from "./environments/environment";

const amplify = Amplify.configure({
  Auth: {
    identityPoolId: environment.identityPoolId,
    region: environment.regionAws,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId
  }, Storage: {
    AWSS3: {
      bucket: environment.bucketName,
      level: 'public',
      region: environment.regionS3
    }
  }
});

export default amplify;
