import {AbstractControl, FormGroup} from '@angular/forms';
import {Injectable} from '@angular/core';
import JSONStrings from 'src/app/core/internalization/es.json';

@Injectable({
  providedIn: 'root',
})
export class InputValidationService {
  constructor() {
  }

  
  shouldShowErrors(field: AbstractControl, formHasBeenSubmitted: boolean, page = 'generic', customError = false, customKey = ''): Array<string> {
    if (field != null) {
      if (field.invalid && formHasBeenSubmitted) {
        return Object.keys(field.errors).map((err) => {
          return JSONStrings[page][err];
        });
      } else {
        return [];
      }
    } else if (customError) {
        return JSONStrings[page][customKey];
    } else {
      return [];
    }
  }

  validateInputClass(form: FormGroup, fieldName: string, submitRegister: boolean): string {
    const formCtrl = form.controls[fieldName];
    if (submitRegister) {
      if (formCtrl.valid) {
        if (formCtrl.touched) {
          return 'has-valid';
        }
      } else {
        return 'has-error';
      }
    } else if (formCtrl.dirty && formCtrl.touched) {
      return 'has-typing';
    } else {
      return '';
    }
  }

  onlyText(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122) ||
      charCode === 32 ||
      charCode === 241 ||
      charCode === 209;
  }

  documentText(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122);
  }
}
