<fieldset
  class="fieldset-input {{customClass}}"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': onFocus,
    'filled': filledInput(),
    'error': shouldShowErrors() || customHint,
    'helper': helperText.length > 1,
    'disabled': readonly || inputField.disabled,
    'success': success,
    'validate': validate,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
>
  <div class="field__input">
    <label class="label" for="input">{{label}}</label>
    <input
      #input
      id="input"
      class="input"
      [name]="name"
      [type]="type"
      formControlName="{{controlName}}"
      (focus)="onFocusInput()"
      (focusout)="onFocusOut()"
      (blur)="onBlurInput()"
      (keyup)="onKeyUpInput($event)"
      (keydown.space)="preventSpace ? $event.preventDefault() : ''"
      autocomplete="off"
    />
    <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && (shouldShowErrors() || customHint)"></i>
    <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success || successIcon"></i>
    <i class="fa-light fa-spinner-third spinner-icon" *ngIf="validate"></i>
  </div>
  <p class="hint" *ngIf="hint && shouldShowErrors()">
    <span *ngFor="let error of listOfErrors">{{error}}</span>
  </p>
  <div *ngIf="showCounter" class="field__item--info">
    <span>{{  inputField.value ? inputField.value.length : 0 }}/{{ maxCounter }}</span>
  </div>
  <div *ngIf="isBidAccessUsername && (onFocus || !formHasBeenSubmitted || listOfErrors?.length === 0)" class="field__item--info">
    <span>Entre 6 y 18 caracteres.</span>
  </div>
  <div class="field__item--info" *ngIf="showInfoAddress">
    <!--<i class="fa-solid fa-location-check"></i>-->
    Verifique que al seleccionar la dirección, la misma sea válida
    <div
      class="tooltip-cursor"
      pTooltip="Para que la dirección sea válida, la ciudad debe autocompletarse. Si no es así, por favor ingrese otra dirección (calle y altura) o ubicación"
      tooltipPosition="top">
      <i class="fa-light fa-circle-info"></i>
    </div>
  </div>
  <p class="custom-hint" *ngIf="customHint">
    <span>{{customHint}}</span>
  </p>

</fieldset>
