import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorTinService {

  constructor() { }

  validateTin(tin) {
    return (tin.toString().length !== 9);
  }
}
