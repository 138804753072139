<p-toast></p-toast>
<app-navbar *ngIf="logged && !isInMaintenance"></app-navbar>

<div class="container-fluid bg-gradient first">
  <p-confirmDialog key="jwtExpired" class="bid-modal bid-modal__warning--small"
                   acceptLabel="Iniciar sesión"></p-confirmDialog>
  <p-confirmDialog key="changeAccount" class="bid-modal bid-modal__warning--small"
                   acceptLabel="Actualizar página"></p-confirmDialog>
  <app-spinner [showSpinner]="showSpinner" [type]="4"></app-spinner>
</div>

<app-lateral-menu-ipad *ngIf="logged && !isInMaintenance && !showLateralMenu" [events]="eventsUrlSubject.asObservable()"
                       class="lateral-menu-ipad"></app-lateral-menu-ipad>
<app-lateral-menu *ngIf="logged && !isInMaintenance && showLateralMenu" [events]="eventsUrlSubject.asObservable()"
                  class="lateral-menu"></app-lateral-menu>

<div class="app__container" [ngClass]="{ 'container-router': logged }">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>

<!-- Error for 500 error -->
<p-dialog [(visible)]="showRequestError" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal bid-modal__warning--small">
  <ng-template pTemplate="header" class="bid-modal__header"></ng-template>
  <ng-template pTemplate="content" class="bid-modal__content">
    <div class="field__group">
      <i class="icon-warning icon-yellow icon-4xl"></i>
      <p class="font-poppins text-lg lh-26 text-gray-1 font-semibold margin-top-32 margin-bottom-0">
        {{ modalTitle ? modalTitle : 'Atención' }}
      </p>
      <p class="font-inter text-sm lh-26 text-gray-35 font-light margin-vertical-0">
        {{ errorMessage }}
      </p>
    </div>
  </ng-template>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button pButton label="Entendido" class="btn btn__primary btn--one" (click)="hideDialogError()"></button>
  </ng-template>
</p-dialog>
