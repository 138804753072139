import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ContactForm} from '../core/models/ContactForm';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public sendMailContact(contact: ContactForm) {
    return this.httpC.post<void>(environment.apiAGUrl + `/contact/backoffice`, {...contact});
  }
}
