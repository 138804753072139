import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AreaGraphicComponent} from './components/area-graphic/area-graphic.component';
import {BarGraphicComponent} from './components/bar-graphic/bar-graphic.component';
import {BarGraphicNumericStepComponent} from './components/bar-graphic-numeric-step/bar-graphic-numeric-step.component';
import {BidInputComponent} from './components/bid-input/bid-input.component';
import {BidInputPasswordComponent} from './components/bid-input-password/bid-input-password.component';
import {BidDropdownComponent} from './components/bid-dropdown/bid-dropdown.component';
import {BidInputMaskComponent} from './components/bid-input-mask/bid-input-mask.component';
import {BidInputAddressSuggestedComponent} from './components/bid-input-address-suggested/bid-input-address-suggested.component';
import {BidInputPasswordRecomendComponent} from './components/bid-input-password-recomend/bid-input-password-recomend.component';
import {BidTextareaComponent} from './components/bid-textarea/bid-textarea.component';
import {BidInputCalendarComponent} from './components/bid-input-calendar/bid-input-calendar.component';
import {BidInputNumberComponent} from './components/bid-input-number/bid-input-number.component';
import {CustomErrorFormComponent} from './components/custom-error-form/custom-error-form.component';
import {BidInputSearchComponent} from './components/bid-input-search/bid-input-search.component';
import {BidSectorsComponent} from './components/bid-sectors/bid-sectors.component';
import {BidInputDniComponent} from './components/bid-input-dni/bid-input-dni.component';
import {DonutGraphicComponent} from './components/donut-graphic/donut-graphic.component';
import {LineGraphicComponent} from './components/line-graphic/line-graphic.component';
import {SaveFixedComponent} from './components/save-fixed/save-fixed.component';
import {ShowErrorsComponent} from './components/show-errors/show-errors.component';
import {ModalComponent} from './components/modal/modal.component';
import {SwitchToggleComponent} from './components/switch-toggle/switch-toggle.component';
import {ShowQrComponent} from './components/show-qr/show-qr.component';
import {LoadImgCroppComponent} from './components/load-img-cropp/load-img-cropp.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {TrimInputDirective} from './directives/trim-input.directive';
import {RegisterKeysPipe} from './pipes/register-keys.pipe';
import {OrderStatusPipe} from './pipes/order-status.pipe';
import {OrderItemStatusPipe} from './pipes/order-item-status.pipe';
import {PaymentStatusPipe} from './pipes/payment-status.pipe';
import {PesosPipe} from './pipes/pesos.pipe';
import {BookingStatusPipe} from './pipes/booking-status.pipe';
import {IndexOfPipe} from './pipes/index-of.pipe';
import {FormControlPipe} from './pipes/form-control.pipe';
import {DiscountTypePipe} from './pipes/discount-type.pipe';
import {AddressPipe} from './pipes/address.pipe';
import {HyphenatePipe} from './pipes/hyphens.pipe';
import {MessagesModule} from 'primeng/messages';
import {DialogModule} from 'primeng/dialog';
import {ButtonModule} from 'primeng/button';
import {InputNumberModule} from 'primeng/inputnumber';
import {FileUploadModule} from 'primeng/fileupload';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {CardModule} from 'primeng/card';
import {InputTextModule} from 'primeng/inputtext';
import {PanelMenuModule} from 'primeng/panelmenu';
import {MenuModule} from 'primeng/menu';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ToolbarModule} from 'primeng/toolbar';
import {RatingModule} from 'primeng/rating';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {MultiSelectModule} from 'primeng/multiselect';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {TabViewModule} from 'primeng/tabview';
import {InputSwitchModule} from 'primeng/inputswitch';
import {MessageModule} from 'primeng/message';
import {InputMaskModule} from 'primeng/inputmask';
import {ProgressBarModule} from 'primeng/progressbar';
import {FullCalendarModule} from '@fullcalendar/angular';
import {CalendarModule} from 'primeng/calendar';
import {AccordionModule} from 'primeng/accordion';
import {PasswordModule} from 'primeng/password';
import {ImageCropperModule} from 'ngx-image-cropper';
import {BlockUIModule} from 'primeng/blockui';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TooltipModule} from 'primeng/tooltip';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {BadgeModule} from 'primeng/badge';
import {TagModule} from 'primeng/tag';
import {TabMenuModule} from 'primeng/tabmenu';
import {SkeletonModule} from 'primeng/skeleton';
import {SplitterModule} from 'primeng/splitter';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QRCodeModule} from 'angularx-qrcode';
import {RouterModule} from '@angular/router';
import { EditEventComponent } from './components/edit-event/edit-event.component';

@NgModule({
  declarations: [
    AreaGraphicComponent,
    BarGraphicComponent,
    BarGraphicNumericStepComponent,
    BidInputComponent,
    BidInputPasswordComponent,
    BidDropdownComponent,
    BidInputMaskComponent,
    BidInputAddressSuggestedComponent,
    BidInputPasswordRecomendComponent,
    BidTextareaComponent,
    BidInputCalendarComponent,
    BidInputNumberComponent,
    CustomErrorFormComponent,
    BidInputSearchComponent,
    BidSectorsComponent,
    BidInputDniComponent,
    DonutGraphicComponent,
    LineGraphicComponent,
    SaveFixedComponent,
    ShowErrorsComponent,
    ModalComponent,
    SwitchToggleComponent,
    ShowQrComponent,
    LoadImgCroppComponent,
    SpinnerComponent,
    TrimInputDirective,
    RegisterKeysPipe,
    OrderStatusPipe,
    OrderItemStatusPipe,
    PaymentStatusPipe,
    PesosPipe,
    BookingStatusPipe,
    IndexOfPipe,
    FormControlPipe,
    DiscountTypePipe,
    AddressPipe,
    HyphenatePipe,
    EditEventComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    RouterModule,
    CommonModule,
    MessagesModule,
    DialogModule,
    ButtonModule,
    InputNumberModule,
    FileUploadModule,
    AutoCompleteModule,
    CardModule,
    InputTextModule,
    PanelMenuModule,
    MenuModule,
    TableModule,
    ToastModule,
    ToolbarModule,
    RatingModule,
    DialogModule,
    ConfirmDialogModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    InputMaskModule,
    ProgressBarModule,
    FullCalendarModule,
    CalendarModule,
    AccordionModule,
    PasswordModule,
    ImageCropperModule,
    BlockUIModule,
    InputTextareaModule,
    TooltipModule,
    RadioButtonModule,
    ContextMenuModule,
    DynamicDialogModule,
    BadgeModule,
    TagModule,
    TabMenuModule,
    SkeletonModule,
    SplitterModule,
    ClipboardModule
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    QRCodeModule,
    RouterModule,
    SpinnerComponent,
    BidInputComponent,
    AreaGraphicComponent,
    BarGraphicComponent,
    BarGraphicNumericStepComponent,
    BidInputPasswordComponent,
    BidDropdownComponent,
    BidInputMaskComponent,
    BidInputAddressSuggestedComponent,
    BidInputPasswordRecomendComponent,
    BidTextareaComponent,
    BidInputCalendarComponent,
    BidInputNumberComponent,
    CustomErrorFormComponent,
    BidInputSearchComponent,
    BidSectorsComponent,
    BidInputDniComponent,
    DonutGraphicComponent,
    LineGraphicComponent,
    SaveFixedComponent,
    ShowErrorsComponent,
    ModalComponent,
    SwitchToggleComponent,
    ShowQrComponent,
    LoadImgCroppComponent,
    MessagesModule,
    DialogModule,
    ButtonModule,
    InputNumberModule,
    FileUploadModule,
    AutoCompleteModule,
    CardModule,
    InputTextModule,
    PanelMenuModule,
    MenuModule,
    TableModule,
    ToastModule,
    ToolbarModule,
    RatingModule,
    DialogModule,
    ConfirmDialogModule,
    CheckboxModule,
    DropdownModule,
    MultiSelectModule,
    ProgressSpinnerModule,
    TabViewModule,
    InputSwitchModule,
    MessagesModule,
    MessageModule,
    InputMaskModule,
    ProgressBarModule,
    FullCalendarModule,
    CalendarModule,
    AccordionModule,
    PasswordModule,
    ImageCropperModule,
    BlockUIModule,
    InputTextareaModule,
    TooltipModule,
    RadioButtonModule,
    ContextMenuModule,
    DynamicDialogModule,
    BadgeModule,
    TagModule,
    TabMenuModule,
    SkeletonModule,
    SplitterModule,
    ClipboardModule,
    TrimInputDirective,
    RegisterKeysPipe,
    OrderStatusPipe,
    OrderItemStatusPipe,
    PaymentStatusPipe,
    PesosPipe,
    BookingStatusPipe,
    IndexOfPipe,
    FormControlPipe,
    DiscountTypePipe,
    AddressPipe,
    HyphenatePipe,
    EditEventComponent
  ],
})
export class SharedModule {
}
