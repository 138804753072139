import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public static isLoggedIn(): boolean {
    return localStorage.getItem('isLoggedIn') === 'true';
  }

  constructor(
    private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const isInMaintenanceMode: boolean = environment.isUnderMaintenance;

    if (isInMaintenanceMode) {
      this.router.navigate(['maintenance']);
    }

    return this.verifyLogin(state.url);
  }

  verifyLogin(url): boolean {
    const logged = AuthGuard.isLoggedIn();
    if (!logged) {
      this.router.navigate(['/login']);
    }
    if (environment.isUnderMaintenance) {
      this.router.navigateByUrl('/maintenance');
      return false;
    }
    return true;
  }
}
