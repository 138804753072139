import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './core/guards/auth.guard';
import {LoginComponent} from './pages/sign-in/login/login.component';
import {MobileAccessComponent} from './core/components/mobile-access/mobile-access.component';
import {NgModule} from '@angular/core';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {RegisterComponent} from './pages/sign-in/register/register.component';
import {WelcomeComponent} from './core/components/welcome/welcome.component';
import {StoreVerificationComponent} from './pages/sign-in/store-verification/store-verification.component';
import {MaintenanceComponent} from './pages/maintenance/maintenance.component';

const routes: Routes = [
  {path: '', redirectTo: '/events/list', pathMatch: 'full'},
  {
    path: 'stats',
    loadChildren: () => import('./pages/stats/stats.module').then(
      (m) => m.StatsModule
    ),
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(
      (m) => m.MenuModule
    ),
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/list/events.module').then(
      (m) => m.EventsModule
    ),
  },
  {
    path: 'event',
    loadChildren: () => import('./pages/events/event/event.module').then(
      (m) => m.EventModule
    ),
  },
  {
    path: 'access-control',
    loadChildren: () => import('./pages/events/access-control/access-control.module').then(
      (m) => m.AccessControlModule
    ),
  },
  {
    path: 'real-time',
    loadChildren: () => import('./pages/control-real-time/control-real-time.module').then(
      (m) => m.ControlRealTimeModule
    ),
  },
  {
    path: 'configuraciones',
    loadChildren: () => import('./pages/configurations/configurations.module').then(
      (m) => m.ConfigurationsModule
    ),
  },
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then(
      (m) => m.PromotionsModule
    ),
  },
  {
    path: 'confirm-registration',
    loadChildren: () => import('./pages/confirm-registration/confirm-registration.module').then(
      (m) => m.ConfirmRegistrationModule
    ),
  },
  {
    path: 'confirm-registration/:token',
    loadChildren: () => import('./pages/confirm-registration/confirm-registration.module').then(
      (m) => m.ConfirmRegistrationModule
    ),
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(
      (m) => m.ForgotPasswordModule
    ),
  },
  {
    path: 'resetPassword/:token',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(
      (m) => m.ForgotPasswordModule
    ),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'verified',
    component: StoreVerificationComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'dashboard',
    component: WelcomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mobile-access',
    component: MobileAccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: true,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
