<fieldset
  class="fieldset-dropdown {{customClass}}"
  [formGroup]="parentForm"
  [ngClass]="{
    'focus': onFocus,
    'filled': onFilled,
    'error': shouldShowErrors(),
    'helper': helperText.length > 1,
    'disabled': readonly,
    'success': success,
    'validate': loading,
    'bg-light-in-mobile': bgLightMobile,
    'bg-light-in-desktop': bgLightDesktop,
    'size-large-mobile': largeMobile,
    'size-large-desktop': largeDesktop,
    'size-large': large
  }"
>
  <div class="field__dropdown">
    <label class="label">{{label}}</label>
    <p-dropdown
      class="bid-dropdown"
      id="dropdown"
      formControlName="{{ controlName }}"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [filter]="false"
      [showClear]="true"
      [disabled]="loading || disabled"
      [options]="options"
      [autoDisplayFirst]="false"
      (onChange)="selectOption($event)"
      (onFocus)="onFocusInput()"
      (onBlur)="onBlurInput()"
    ></p-dropdown>

    <i class="fa-solid fa-triangle-exclamation text-error text-icon" *ngIf="withIcon && shouldShowErrors()"></i>
    <i class="fa-solid fa-circle-check text-success text-icon" *ngIf="success"></i>
    <i class="fa-light fa-spinner-third spinner-icon" *ngIf="loading"></i>
  </div>
  <p class="hint" *ngIf="hint && shouldShowErrors()">
    <span *ngFor="let error of listOfErrors()">{{error}}</span>
  </p>
</fieldset>
