import {Injectable} from '@angular/core';
import {FormattedGoogleResult} from '../core/models/configurations/FormattedGoogleResult';

declare var google;

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  GoogleAutocomplete: any;

  constructor() {
    this.GoogleAutocomplete = new google.maps.places.AutocompleteService();
  }

  async getPredictions(input) {
    return new Promise<any[]>(async (resolve, rejects) => {
      let autocompleteItems: any[] = [];
      this.GoogleAutocomplete.getPlacePredictions({input},
        (predictions, status) => {
          autocompleteItems = [];
          for (const prediction of predictions) {
            autocompleteItems.push(prediction);
          }
          resolve(autocompleteItems);
        });
    });
  }

  findData(param, result) {
    let data = result.address_components.find(r => r.types[r.types.indexOf(param)] === param);
    if (data) {
      return data.long_name;
    }
    const obj = {
      locality: 'sublocality'
    };
    data = result.address_components.find(r => r.types[r.types.indexOf(obj[param])] === obj[param]);
    if (data) {
      return data.long_name;
    }

    return '';
  }

  getGeocodedResult(fullAddress, complete) {
    return new Promise<FormattedGoogleResult>((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({'address': fullAddress}, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const long = results[0].geometry.location.lng();
          resolve({
            latitude: parseFloat(lat),
            longitude: parseFloat(long),
            city: this.findData('locality', results[0]),
            address: !complete ? `${this.findData('route', results[0])} ${this.findData('street_number', results[0])}` : `${this.findData('route', results[0])} ${this.findData('street_number', results[0])}, ${this.findData('locality', results[0])}`
          });
        }
      });
    });
  }

}
