import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ConfigurationService} from '../../configurations/services/configuration.service';
import {Router} from '@angular/router';
import {StoreData} from '../../../core/models/auth/StoreData';
import {Subscription} from 'rxjs';
import {LoginRequest} from '../../../core/models/auth/LoginRequest';
import {Configurations} from '../../../core/models/configurations/Configurations.interface';
import {SpinnerService} from '../../../services/spinner.service';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],

})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  displayDialog: boolean;
  contentDialog: string;
  showError: boolean;
  contentError: string;
  txtButton: string;
  widthWindowMobile = false;
  subs: Subscription[] = [];
  @ViewChild('email') email: ElementRef;
  invalid = false;
  formHasBeenSubmitted = false;
  isReadOnly = true;
  formSubscribe;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private configService: ConfigurationService,
    private router: Router,
    private spinnerService: SpinnerService
  ) {
    this.txtButton = 'Ingresar';
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    const logged = localStorage.getItem('isLoggedIn');

    if (this.widthWindowMobile) {
      if (logged) {
        this.router.navigate(['mobile-access']);
      }
    } else {
      if (logged) {
        this.router.navigate(['events', 'list']);
      }
    }

    setTimeout(() => {
      this.isReadOnly = false;
    }, 2000);

    this.subs.push(
      this.loginForm.get('email').valueChanges.subscribe({
        next: event => {
          this.loginForm.get('email').setValue(event.toLowerCase(), {emitEvent: false});
        }
      })
    );

    this.formSubscribe = this.loginForm.valueChanges.subscribe({
      next: () => {
        this.formHasBeenSubmitted = false;
        this.showError = false;
      }
    });
  }

  detectMob() {
    this.widthWindowMobile = window.innerWidth <= 800;
    return this.widthWindowMobile;
  }

  public isLoggedIn(): boolean {
    const logged = localStorage.getItem('isLoggedIn');
    return !!logged;
  }

  onForgetPassword() {
    this.router.navigate(['resetPassword']);
  }

  submitLogin() {
    this.formHasBeenSubmitted = true;
    this.spinnerService.loadSpinner.next(true);
    this.showError = false;
    const form: LoginRequest = this.loginForm.value;
    localStorage.clear();
    this.loading = true;
    this.subs.push(
      this.authService.login(form).subscribe({
        next: async (res: StoreData) => {
          localStorage.setItem('BE-JWT', res.jwt);
          localStorage.setItem('isLoggedIn', 'true');
          this.authService.userIsLoggedIn$.next(true);
          this.authService.updateUser(res);
          await this.loadingConfig(res.id);
          if (this.detectMob()) {
            if (this.isLoggedIn) {
              await this.router.navigate(['mobile-access']);
            }
          } else {
            if (this.isLoggedIn) {
              await this.router.navigate(['events', 'list']);
            }
          }
          this.loading = false;
          this.spinnerService.loadSpinner.next(false);
        },
        error: (err) => {
          this.loading = false;
          this.spinnerService.loadSpinner.next(false);
          this.contentError = 'Error al iniciar sesión.';
          switch (err.status) {
            case 401:
              this.router.navigate(['confirm-registration'], {
                state: {email: form.email},
              });
              break;
            case 403:
              this.showError = true;
              this.contentError = 'Credenciales inválidas. Intentá de nuevo.';
              break;
            case 423:
              this.router.navigate(['verified']);
              break;
            case 409:
              this.showError = true;
              this.contentError = 'La cuenta ha sido bloqueada, comunicate con soporte.';
              break;
            default:
              this.showError = true;
              this.loading = false;
              this.contentError = 'Error al iniciar sesión.';
              break;
          }
        }
      })
    );
  }

  loadingConfig(id): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.subs.push(
        this.configService.getConfigurations(id).subscribe({
          next: (res: Configurations) => {
            const data = {
              bookingAvailable: res.bookingConfiguration.bookingAvailable,
              menuAvailable: res.menuConfiguration.menuAvailable,
              scheduleAvailable: res.scheduleConfiguration.scheduleAvailable,
              tableAvailable: res.tableConfiguration.tableAvailable,
              waitingListAvailable:
              res.waitingListConfiguration.waitingListAvailable,
              allowCardPayments: res.allowCardPayments,
            };
            localStorage.setItem('configs', JSON.stringify(data));
            resolve();
          }
        })
      );
    });
  }

  displayError() {
    this.showError = false;
  }

  ngOnDestroy() {
    this.subs.forEach((s: Subscription) => {
      s.unsubscribe();
    });
    this.formSubscribe.unsubscribe();
  }

}
