import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ErrorRequest} from '../core/models/ErrorRequest';

@Injectable({
  providedIn: 'root'
})
export class RequestErrorTriggerService {
  private showErrorSubject: BehaviorSubject<ErrorRequest> = new BehaviorSubject({showError: false, message: ''});

  public readonly showError: Observable<ErrorRequest> = this.showErrorSubject.asObservable();

  constructor() {
  }

  updateShowError(show: ErrorRequest) {
    this.showErrorSubject.next(show);
  }

}
