<fieldset>
  <bid-input
    [label]="'Dirección*'"
    [type]="'text'"
    [page]="'configurations'"
    [largeMobile]="true"
    [controlName]="controlName"
    [parentForm]="parentForm"
    [bgLightDesktop]="true"
    [max]="maxlength"
    [formHasBeenSubmitted]="formHasBeenSubmitted"
    [customClass]="customClass"
    (keyUpChange)="onKeyUp($event)"
    [showInfoAddress]="true"
  ></bid-input>
  <div
    class="dropdown__list"
    *ngIf="showSuggestions"
    [ngClass]="{ show: suggestedAddress.length > 0 && showSuggestionsResult }">
    <a
      *ngFor="let result of suggestedAddress"
      (click)="selectSuggestedAddress(result)"
      class="dropdown__items"
    >{{ result.description }}</a>
  </div>

</fieldset>
