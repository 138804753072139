import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {AvailableCountry} from '../core/models/configurations/AvailableCountry';

@Injectable({
  providedIn: 'root'
})
export class CommerceService {

  constructor(
    private httpC: HttpClient
  ) {
  }

  public getAvailableCountries(): Observable<AvailableCountry[]> {
    return this.httpC.get<AvailableCountry[]>(environment.apiAGUrl + `/stores/available-countries`);
  }
}
