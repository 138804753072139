import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {FormattedGoogleResult} from 'src/app/core/models/configurations/FormattedGoogleResult';
import {GoogleService} from 'src/app/services/google.service';

@Component({
  selector: 'bid-input-address-suggested',
  templateUrl: './bid-input-address-suggested.component.html',
  styleUrls: ['./bid-input-address-suggested.component.scss']
})
export class BidInputAddressSuggestedComponent implements OnInit {

  @Input() label = '';
  @Input() required = false;
  @Input() onFocus = false;
  @Input() onError = false;
  @Input() success = false;
  @Input() type = 'text';
  @Input() name = '';
  @Input() hint = true;
  @Input() ctrl: FormControl;
  @Input() page = '';
  @Input() capitalize = false;
  @Input() lowerCase = false;
  @Input() uppercase = false;
  @Input() formHasBeenSubmitted = false;
  @Input() preventSpace = false;
  @Input() customClass = '';
  @Input() withIcon = true;
  @Input() onFilled = false;
  @Input() maxlength = 320;
  @Input() minlength = 1;
  @Input() customError = false;
  @Input() validate = false;
  @Input() bgLightMobile = false;
  @Input() bgLightDesktop = false;
  @Input() large = false;
  @Input() largeMobile = false;
  @Input() largeDesktop = false;
  @ViewChild('input') input: ElementRef;
  @Input() loading;
  @Input() disabled;
  suggestedAddress = [];
  typingTimer: any;
  @Input() autoCompleteAddress: string;
  @Input() addressLocal = false;
  @Input() address: string = ''; //original address
  @Output() addressSelected = new EventEmitter<string>();
  value: any;
  helperText = '';
  @Input() parentForm: FormGroup;
  @Input() controlName: string;

  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';

  @Input() showSuggestions = true;
  showSuggestionsResult = false;


  constructor(
    private googleSrv: GoogleService
  ) {
  }

  get inputField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
    this.address = this.inputField.value;
  }

  onKeyUp(e) {
    this.addressChange();
  }

  async addressChange() {
    if (this.inputField.value !== this.address) {
      this.validate = true;
      this.suggestedAddress = await this.googleSrv.getPredictions(this.inputField.value);
      if (this.suggestedAddress.length) {
        const {latitude, longitude, city, address}: FormattedGoogleResult =
          await this.googleSrv.getGeocodedResult(
            this.suggestedAddress[0].description,
            false
          );
          this.showSuggestionsResult = true;
      }
      this.validate = false;
    } else {
      this.showSuggestionsResult = false;
    }
  }

  async selectSuggestedAddress(result) {
    this.showSuggestionsResult = false;
    this.address = result.description;
    const {latitude, longitude, city, address}: FormattedGoogleResult =
      await this.googleSrv.getGeocodedResult(this.address, false);

    this.value = [{
      latitude,
      longitude,
      city,
      address,
      inputValue: this.address
    }];

    this.suggestedAddress = [];
    this.inputField.patchValue(this.address);
    this.parentForm.controls.city.patchValue(city);
    this.addressSelected.emit(this.value);

  }
}
