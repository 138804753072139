import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {Subject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {mergeMapTo} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {NotificationsData} from '../core/models/NotificationsData';
import {NotificationType} from '../shared/enums/NotificationType';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  notificationsSubject$ = new Subject();
  notificationsUpdate$ = new Subject();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private httpC: HttpClient,
    private authService: AuthService
  ) {
  }

  getUnreadNotifications(): Observable<NotificationsData> {
    const {id} = this.authService.getLocalUserData();
    return this.httpC.get<NotificationsData>(`${environment.apiAGUrl}/notification/by_store/${id}`);
  }

  initializeToken() {
    this.angularFireMessaging.requestPermission
      .pipe(mergeMapTo(this.angularFireMessaging.tokenChanges)).subscribe({
      next: (token: string) => {
        this.saveToken(token);
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  private saveToken(token) {
    const {id} = this.authService.getLocalUserData();
    this.httpC.post<any>(`${environment.apiAGUrl}/stores/fcmtoken/${id}`, {token}).subscribe();
  }

  markNotificationsAsSeen(type: NotificationType): Observable<NotificationsData> {
    const {id} = this.authService.getLocalUserData();
    return this.httpC.patch<NotificationsData>(`${environment.apiAGUrl}/notification/mark_as_seen/by_store/${id}`, {type});
  }

}
