import {Injectable} from '@angular/core';
import {SignUpRequest} from '../core/models/auth/SignUpRequest';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {StoreData} from '../core/models/auth/StoreData';
import {ImageUploadRequest} from '../core/models/configurations/ImageUploadRequest';
import {ImageUploadResponse} from '../core/models/configurations/ImageUploadResponse';
import {LoginRequest} from '../core/models/auth/LoginRequest';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiAGUrl;

  userIsLoggedIn$ = new Subject<boolean>();
  storeData: BehaviorSubject<StoreData>;
  private userSubject: BehaviorSubject<StoreData> = new BehaviorSubject(new StoreData());

  public readonly user: Observable<StoreData> = this.userSubject.asObservable();

  constructor(
    private http: HttpClient,
  ) {
    if (JSON.parse(localStorage.getItem('storeData')) !== null) {
      this.userSubject.next(JSON.parse(localStorage.getItem('storeData')));
    }
  }

  register(form: SignUpRequest) {
    return this.http.post(`${this.apiUrl}/stores`, form);
  }

  public login(authIn: LoginRequest): Observable<StoreData> {
    return this.http.post<any>(`${environment.apiAGUrl}/stores/login`, authIn);
  }

  public validateToken(token: string): Observable<any> {
    return this.http.get<any>(`${environment.apiAGUrl}/registrationConfirm/${token}`);
  }

  public reSendEmail(token: string): Observable<any> {
    return this.http.get<any>(`${environment.apiAGUrl}/store/resendRegistrationToken/${token}`);
  }

  public resendEmailToken(email: string) {
    return this.http.get<any>(`${environment.apiAGUrl}/store/resendEmailToken/${email}`);
  }

  public resetPassword(email: string): Observable<any> {
    return this.http.post<any>(`${environment.apiAGUrl}/store/resetPassword/${email}`, null);
  }

  public changePassword(token: string): Observable<any> {
    return this.http.get<any>(`${environment.apiAGUrl}/store/changePassword/${token}`);
  }

  public sendNewPass(newPass): Observable<any> {
    return this.http.post<any>(`${environment.apiAGUrl}/store/savePassword`, newPass);
  }

  public getCurrentUser(): Observable<StoreData> {
    const {id} = this.getLocalUserData();
    return this.http.get<StoreData>(`${environment.apiUrl}/stores/by_id/${id}`);
  }

  updateUser(user: StoreData) {
    localStorage.setItem('storeData', JSON.stringify(user));
    this.userSubject.next(user);
  }

  getLocalUserData(): StoreData {
    return this.userSubject.value;
  }

  public uploadEventImage(request: ImageUploadRequest): Observable<ImageUploadResponse> {
    return this.http.post<ImageUploadResponse>(environment.apiAGUrl + `/store/upload`, request);
  }
}
