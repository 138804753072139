<div class="w-100 min-h-screen">
  <div class="p-d-flex p-jc-center p-ai-center min-h-screen">
    <div class="container md:max-w-screen-sm pt-20">
      <div class="p-d-flex p-flex-column">
        <div class="download-app my-1 text-center">
          <div class="head-title my-2">
            <h1
              class="
                font-sans
                text-2xl
                md:text-3xl
                lg:text-4xl
                font-semibold
                leading-none
                text-gray-1
                mb-2
              "
            >
              Acceso Backoffice
            </h1>
            <p
              class="
                font-sans
                text-base
                md:text-lg
                font-normal
                leading-4
                text-gray-1
                mb-4
                relative
              "
            >
              Ingresá desde una pc, laptop o tablet para poder gestionar tu
              negocio.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
