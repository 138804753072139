import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'registerKeys'
})
export class RegisterKeysPipe implements PipeTransform {

  transform(value: string): unknown {
    const translations = {
      taxIdentifier: 'Identificador fiscal',
      name: 'Nombre',
      address: 'Dirección',
      phone: 'Número de Telefono',
      email: 'Email',
      city: 'Ciudad',
      newEmail: 'Nuevo Email'
    };
    return translations[value];
  }

}
