<nav class="navbar" [ngClass]="{ 'bg-solid-dark': isHome }">
  <a href="/" target="_self">
    <img src="../../../../assets/images/logo-blackid.svg" class="logo" alt="Blackid"/>
  </a>

  <div class="navbar__end">
    <app-global-alert></app-global-alert>

    <ul class="navbar__list">
      <li class="navbar__item" *ngIf="showExit">
        <button pButton class="btn btn__secondary--b btn__size--xs" label="Contacto" (click)="openContact()"></button>
      </li>
    </ul>
  </div>
</nav><!-- Navbar -->

<p-dialog [(visible)]="display" [draggable]="false" [resizable]="false" [modal]="true" class="bid-modal">
  <ng-template pTemplate="header" class="bid-modal__header">
    <div class="filling-space"></div>
    <p class="bid-modal__header--title">Contactate con nosotros</p>
  </ng-template>

  <ng-template pTemplate="content" class="bid-modal__content">
    <form [formGroup]="contactForm" class="form">
      <fieldset class="field__group">
        <bid-input
          [label]="'Nombre*'"
          [type]="'text'"
          [name]="'name'"
          [page]="'contact'"
          [max]="maxLengthName"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [controlName]="'name'"
          [parentForm]="contactForm"
          [showCounter]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [customClass]="'margin-bottom-8'"
        ></bid-input>

        <bid-input
          [label]="'Email*'"
          [type]="'text'"
          [name]="'email'"
          [page]="'contact'"
          [largeMobile]="true"
          [preventSpace]="true"
          [bgLightDesktop]="true"
          [max]="maxLengthEmail"
          [showCounter]="true"
          [controlName]="'email'"
          [parentForm]="contactForm"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [customClass]="'margin-bottom-8'"
        ></bid-input>

        <bid-dropdown
          [name]="'reason'"
          [page]="'contact'"
          [optionLabel]="'option'"
          [optionValue]="'option'"
          [label]="'Tipo de consulta*'"
          [controlName]="'reason'"
          [parentForm]="contactForm"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [options]="questions"
        ></bid-dropdown>

        <bid-textarea
          [label]="'Consulta*'"
          [type]="'text'"
          [name]="'content'"
          [page]="'contact'"
          [controlName]="'content'"
          [parentForm]="contactForm"
          [largeMobile]="true"
          [preventSpace]="true"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [maxlength]="255"
        ></bid-textarea>
      </fieldset>
    </form>
  </ng-template>

  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button pButton class="btn btn__secondary" (click)="hideDialog()"
            label="Cancelar"></button>
    <button pButton class="btn btn__primary" (click)="submitContact()"
            [disabled]="contactForm.invalid && formHasBeenSubmitted"
            label="Enviar"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog key="confirmLogout" acceptLabel="Aceptar" rejectLabel="Cancelar"
                 acceptButtonStyleClass="btn btn__primary" rejectButtonStyleClass="btn btn__secondary"
                 class="bid-modal bid-modal__warning--small">
</p-confirmDialog>
