import * as Hypher from 'hypher';
import * as spanish from 'hyphenation.es';

import {Pipe, PipeTransform} from '@angular/core';

interface HyphenateOptions {
  onlyNLongest?: number;
  longerThan?: number;
}

/**
 * Hyphenates given text, based on Hypher library
 * @example
 *  'gonzalolodithebest' | hyphens
 *  formats to: 'gonzalo-lodi-the-best'
 *  (with ­&shy; entities in place of hyphens)
 */
@Pipe({
  name: 'hyphenate'
})

export class HyphenatePipe implements PipeTransform {
  private hyphenator: Hypher = null;
  private hyphenChar = '\u00AD';

  constructor() {
    this.hyphenator = new Hypher(spanish);
  }

  transform(text: string, options: HyphenateOptions = {}): string {
    const words = text.split(/\s+/);
    const hyphenateNLongest = Math.min(
      words.length, options.onlyNLongest || words.length
    );
    const hyphenateLongerThan = options.longerThan || 0;
    const wordsToHyphenate = words
      .concat()
      .sort((word1, word2) => word2.length - word1.length)
      .slice(0, hyphenateNLongest)
      .filter(word => word.length > hyphenateLongerThan);
    return words
      .map(word => {
        if (wordsToHyphenate.indexOf(word) !== -1) {
          return this.hyphenator.hyphenate(word).join(this.hyphenChar);
        }
        return word;
      })
      .join(' ');
  }

}
