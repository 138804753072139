import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import JSONStrings from 'src/app/core/internalization/es.json';

@Component({
  selector: 'bid-dropdown',
  templateUrl: './bid-dropdown.component.html',
  styleUrls: ['./bid-dropdown.component.scss']
})
export class BidDropdownComponent implements OnInit, AfterViewInit {

  @Input() label = 'Campo';
  @Input() required = false;
  @Input() onFocus = false;
  @Input() onError = false;
  @Input() readonly = false;
  @Input() success = false;
  @Input() type = 'text';
  @Input() name = '';
  @Input() hint = true;
  @Input() options: any;
  @Input() ctrl: FormControl;
  @Input() page = '';
  @Input() capitalize = false;
  @Input() lowerCase = false;
  @Input() uppercase = false;
  @Input() formHasBeenSubmitted = false;
  @Input() preventSpace = false;
  @Input() customClass = '';
  @Input() withIcon = true;
  @Output() data = new EventEmitter<string>();
  @Input() onFilled = false;
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() customError = false;
  @Input() validate = false;
  @Input() bgLightMobile = false;
  @Input() bgLightDesktop = false;
  @Input() large = false;
  @Input() largeMobile = false;
  @Input() largeDesktop = false;
  @ViewChild('input') input: ElementRef;
  @Input() optionLabel;
  @Input() optionValue;
  @Input() disabled;
  // Dropdown
  @Input() loading: any;
  value: any;
  selectedOption = true;
  dropdown: any;

  @Input() controlName: string;
  @Input() parentForm: FormGroup;

  helperText = '';
  /* THIS CONFIG IS FOR CUSTOM TRANSLATE */
  @Input() customString = false;
  @Input() customKey = '';

  constructor() {
  }

  get inputField(): AbstractControl {
    return this.parentForm.get(this.controlName);
  }

  ngOnInit(): void {
    this.filledInput();
  }

  ngAfterViewInit() {
    this.shouldShowErrors();
    this.checkIsFocus();
  }
  
  onFocusInput() {
    this.onFocus = true;
    this.onError = false;
  }

  onBlurInput() {
    this.onFocus = false;
  }

  checkIsFocus() {
    if (this.onFocus) {
      this.onError = false;
    }
  }

  filledInput() {
    if (this.inputField.value) {
      this.onFilled = true;
      this.onFocus = false;
      this.onError = false;
    }
  }

  selectOption(event) {
    this.value = event.value;
    this.filledInput();
    this.data.emit(this.value);
  }

  shouldShowErrors(): boolean {
    if (this.inputField != null) {
      if (this.inputField.invalid && this.formHasBeenSubmitted) {
        this.onError = true;
      }
    } else {
      this.onError = this.customError;
    }
    return this.onError;
  }

  listOfErrors(): string[] {
    if (this.customString) {
      if (this.customKey) {
        return JSONStrings[this.page][this.customKey];
      } else {
        // console.error('This component needs customKey when custom is TRUE');
      }
    } else if (this.inputField != null) {
      return Object.keys(this.inputField.errors).map((err) => {

        return JSONStrings[this.page][err];
      });
    }
  }
}
