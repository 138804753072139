import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorCnpjService {

  constructor() {
  }

  validateCNPJ(cnpj: string) {
    let size;
    let numbers;
    let digits;
    let addition;
    let pos;
    let result;
    if (cnpj === '' || cnpj === null) {
      return false;
    }
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) {
      return false;
    }
    // Eliminate known invalid CNPJs
    if (cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999') {
      return false;
    }
    // Valid DVs
    size = cnpj.length - 2;
    numbers = cnpj.substring(0, size);
    digits = cnpj.substring(size);
    addition = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      addition += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = addition % 11 < 2 ? 0 : 11 - addition % 11;
    if (result !== Number(digits.charAt(0))) {
      return false;
    }
    size = size + 1;
    numbers = cnpj.substring(0, size);
    addition = 0;
    pos = size - 7;
    for (let i = size; i >= 1; i--) {
      addition += numbers.charAt(size - i) * pos--;
      if (pos < 2) {
        pos = 9;
      }
    }
    result = addition % 11 < 2 ? 0 : 11 - addition % 11;
    return result === Number(digits.charAt(1));
  }
}
