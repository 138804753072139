<nav class="sidebar-container scrollbar-hide">
  <div class="btn-menu" (click)="openAsideMenu()" [ngClass]="openLateralMenu == false ? 'active' : ''">
  </div>
  <div class="sidebar shadow-xl" [ngClass]="openLateralMenu == true ? 'expand' : ''">
    <ul class="header-sidebar" (click)="openAsideMenu()">
      <li class="item item-header" id="itemStore">
        <div class="item-logo">
          <img id="ItemPreview" src="{{ urlImage }}" class="logo-store" alt="{{ storeData.name }}"/>
        </div>
        <div class="extend-info">
          <span class="item-name-store">{{ storeData.name }}</span>
        </div>
        <div class="btn-menu-expanded" [ngClass]="openLateralMenu == true ? 'active' : ''">
          <i class="pi pi-chevron-circle-left arrow-menu"></i>
        </div>
      </li>
    </ul>
    <div class="list-items">
      <ul class="nav-items">
        <li class="item">
          <a class="item-tab item-header-tree events" [ngClass]=" isEventsRout ? 'show': ''" [routerLink]="'/'">
            <div class="item-header-tab">
              <div class="item-icon">
                <i class="fa-light fa-calendar-star fa-xl icon"></i>
                <i class="fa-solid fa-calendar-star fa-xl icon__active"></i>
                <span class="tooltiptext">Eventos</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Eventos</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab tem-header-tree stats" [ngClass]="{ 'show': isHomeRout }" [routerLink]="'/stats/event'">
            <div class="item-header-tab">
              <div class="item-icon">
                <i class="fa-light fa-chart-line-up fa-xl icon"></i>
                <i class="fa-solid fa-chart-line-up fa-xl icon__active"></i>
                <span class="tooltiptext">Estadísticas</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Estadísticas</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab item-header-tree control-real-time" [ngClass]="{ 'show': isRealTimeRout }"
             [routerLink]="'/real-time/pedidos-mesa'">
            <div class="item-header-tab">
              <div class="item-icon">
                <p-badge *ngIf="notificationsCounter && (countRealTimeNotifications() > 0)"
                         [value]="countRealTimeNotifications().toString(10)" class="custom-badge count-badge">
                </p-badge>
                <i class="fa-light fa-timer fa-xl icon"></i>
                <i class="fa-solid fa-timer fa-xl icon__active"></i>
                <span class="tooltiptext">Control Real Time</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Control Real Time</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab promotions" [ngClass]="{ 'show': isPromotionsRout }" [routerLink]="'/promotions'">
            <div class="item-header-tab">
              <div class="item-icon">
                <i class="fa-light fa-badge-percent fa-xl icon"></i>
                <i class="fa-solid fa-badge-percent fa-xl icon__active"></i>
                <span class="tooltiptext">RR.PP. & Promociones</span>
              </div>
              <div class="extend-info">
                <span class="item-name">RRPP & Promos</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab item-header-tree menu" [ngClass]="{ 'show': isMenuRout }" [routerLink]="'/menu/productos'">
            <div class="item-header-tab">
              <div class="item-icon">
                <i class="fa-light fa-utensils fa-xl icon"></i>
                <i class="fa-solid fa-utensils fa-xl icon__active"></i>
                <span class="tooltiptext">Menú</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Menú</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab configuraciones" [ngClass]="{ 'show': isConfigRout }">
            <div class="item-header-tab" [routerLink]="'/configuraciones'">
              <div class="item-icon">
                <i class="fa-light fa-gear fa-xl icon"></i>
                <i class="fa-solid fa-gear fa-xl icon__active"></i>
                <span class="tooltiptext">Configuraciones</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Configuraciones</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="middle-menu"></div>
      <ul class="footer-sidebar">
        <li class="item item-logout">
          <div class="item-tab logout" (click)="openLogout()">
            <div class="item-icon">
              <i class="fa-light fa-right-from-bracket fa-xl i-logout"></i>
              <span class="tooltiptext">Cerrar sesión</span>
            </div>
            <div class="extend-info">
              <span class="item-name">Cerrar sesión</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<p-dialog [(visible)]="display" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal bid-modal__warning--small">
  <ng-template pTemplate="header" class="bid-modal__header"></ng-template>
  <ng-template pTemplate="content" class="bid-modal__content">
    <i class="icon-warning icon-yellow icon-4xl p-my-0"></i>
    <p class="font-poppins text-gray-1 text-lg lh-24 font-semibold margin-top-32 margin-bottom-0">¿Deseas cerrar
      sesión?</p>
  </ng-template>
  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button pButton label="Cancelar" class="btn btn__secondary" (click)="hideDialog()"></button>
    <button pButton label="Cerrar sesión" class="btn btn__primary" (click)="logout()"></button>
  </ng-template>
</p-dialog>
