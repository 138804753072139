<nav class="sidebar-container">
  <div class="btn-menu" (click)="openAsideMenu()" [ngClass]="openLateralMenu == false ? 'active' : ''">
  </div>
  <div class="sidebar shadow-xl" [ngClass]="openLateralMenu == true ? 'expand' : ''">
    <ul class="header-sidebar" (click)="openAsideMenu()">
      <li class="item item-header" id="itemStore">
        <div class="item-logo">
          <img id="ItemPreview" src="{{ urlImage }}" class="logo-store" alt="{{ storeData.name }}"/>
        </div>
        <div class="extend-info">
          <span class="item-name-store">{{ storeData.name }}</span>
        </div>
        <div class="btn-menu-expanded" [ngClass]="openLateralMenu == true ? 'active' : ''">
          <i class="pi pi-chevron-circle-left arrow-menu"></i>
        </div>
      </li>
    </ul>
    <div class="list-items">
      <ul class="nav-items">
        <li class="item">
          <a class="item-tab item-header-tree events" [ngClass]=" isEventsRout ? 'show': '' ">
            <div class="item-header-tab" (click)="expandMenu('events', 'events-list', '/events/list')">
              <div class="item-icon">
                <i class="fa-light fa-calendar-star fa-xl icon"></i>
                <i class="fa-solid fa-calendar-star fa-xl icon__active"></i>
                <span class="tooltiptext">Eventos</span>
              </div>
              <div class="tab-name">
                <span class="name">EVENTOS</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Eventos</span>
              </div>
            </div>
          </a>
        </li>
        <li class="item">
          <a class="item-tab item-header-tree stats" [ngClass]="{ 'show': isHomeRout }">
            <div class="item-header-tab" (click)="expandMenu('stats', 'stats', '/stats/event')">
              <div class="item-icon">
                <i class="fa-light fa-chart-line-up fa-xl icon"></i>
                <i class="fa-solid fa-chart-line-up fa-xl icon__active"></i>
                <span class="tooltiptext">Estadísticas</span>
              </div>
              <div class="tab-name">
                <span class="name">Estadísticas</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Estadísticas</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab item-header-tree control-real-time" [ngClass]="{ 'show': isRealTimeRout }">
            <div class="item-header-tab"
                 (click)="expandMenu('control-real-time', 'pedidos-real-time', '/real-time/pedidos-mesa')">
              <div class="item-icon">
                <p-badge *ngIf="notificationsCounter && (countRealTimeNotifications() > 0)"
                         [value]="countRealTimeNotifications().toString(10)" class="custom-badge count-badge">
                </p-badge>
                <i class="fa-light fa-timer fa-xl icon"></i>
                <i class="fa-solid fa-timer fa-xl icon__active"></i>
                <span class="tooltiptext">Control Real Time</span>
              </div>
              <div class="tab-name">
                <span class="name">REAL TIME</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Control Real Time</span>
              </div>
            </div>

            <ul class="sub-items">
              <li class="sub-item">
                <a class="sub-item-tab pedidos-mesa" [ngClass]="{ 'is-active': isRealTimeTableRout }"
                   (click)="tab('pedidos-mesa', '/real-time/pedidos-mesa')">
                  <div class="item-icon">
                    <p-badge *ngIf="notificationsCounter && notificationsCounter.unreadTableNotifications > 0"
                             [value]="notificationsCounter.unreadTableNotifications.toString()"
                             class="custom-badge count-badge">
                    </p-badge>
                    <i class="icon icon-bandejiten icon-md"></i>
                    <span class="tooltiptext">Pedidos en Mesa</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">En Mesa</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Pedidos en Mesa</span>
                  </div>
                </a>
              </li>
              <li class="sub-item">
                <a class="sub-item-tab reservations" [ngClass]="{ 'is-active': isRealTimeBookingsRout }"
                   (click)="tab('reservations', '/real-time/reservas')">
                  <div class="item-icon">
                    <p-badge *ngIf="notificationsCounter && notificationsCounter.unreadReservationsNotification > 0"
                             [value]="notificationsCounter.unreadReservationsNotification.toString()"
                             class="custom-badge count-badge">
                    </p-badge>
                    <i class="fa-light fa-calendar-check fa-xl icon"></i>
                    <span class="tooltiptext">Reservas</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">Reservas</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Reservas</span>
                  </div>
                </a>
              </li>
              <li class="sub-item">
                <a class="sub-item-tab lista-espera" [ngClass]="{ 'is-active': isRealTimeWaitingListRout }"
                   (click)="tab('lista-espera', '/real-time/lista-espera')">
                  <div class="item-icon">
                    <p-badge *ngIf="notificationsCounter && notificationsCounter.unreadWaitingListNotification > 0"
                             [value]="notificationsCounter.unreadWaitingListNotification.toString()"
                             class="custom-badge count-badge">
                    </p-badge>
                    <i class="fa-light fa-clipboard-list-check fa-xl icon"></i>
                    <span class="tooltiptext">Lista de espera</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">Lista espera</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Lista de espera</span>
                  </div>
                </a>
              </li>
            </ul>
          </a>
        </li>

        <li class="item">
          <a class="item-tab promotions" [ngClass]="{ 'show': isPromotionsRout }"
             (click)="expandMenu('promotions', 'promotions', '/promotions')">
            <div class="item-header-tab">
              <div class="item-icon">
                <i class="fa-light fa-badge-percent fa-xl icon"></i>
                <i class="fa-solid fa-badge-percent fa-xl icon__active"></i>
                <span class="tooltiptext">Promociones</span>
              </div>
              <div class="tab-name">
                <span class="name">RRPP & Promos</span>
              </div>
              <div class="extend-info">
                <span class="item-name">RRPP & Promos</span>
              </div>
            </div>
          </a>
        </li>

        <li class="item">
          <a class="item-tab item-header-tree menu" [ngClass]="{ 'show': isMenuRout }">
            <div class="item-header-tab" (click)="expandMenu('menu', 'products', '/menu/productos')">
              <div class="item-icon">
                <i class="fa-light fa-utensils fa-xl icon"></i>
                <i class="fa-solid fa-utensils fa-xl icon__active"></i>
                <span class="tooltiptext">Menú</span>
              </div>
              <div class="tab-name">
                <span class="name">Menú</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Menú</span>
              </div>
            </div>

            <ul class="sub-items">
              <li class="sub-item">
                <a class="sub-item-tab products" [ngClass]="{ 'is-active': isMenuProductsRout }"
                   (click)="tab('products', '/menu/productos')">
                  <div class="item-icon">
                    <i class="fa-light fa-burger-soda fa-xl icon"></i>
                    <span class="tooltiptext">Productos</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">Productos</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Productos</span>
                  </div>
                </a>
              </li>
              <li class="sub-item">
                <a class="sub-item-tab categories" [ngClass]="{ 'is-active': isMenuCategoriesRout }"
                   (click)="tab('categories', '/menu/categories')">
                  <div class="item-icon">
                    <i class="fa-light fa-clipboard-list-check fa-xl icon"></i>
                    <span class="tooltiptext">Categorías</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">Categorías</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Categorías</span>
                  </div>
                </a>
              </li>
              <li class="sub-item">
                <a class="sub-item-tab options" [ngClass]="{ 'is-active': isMenuAttributesRout }"
                   (click)="tab('options', '/menu/atributos')">
                  <div class="item-icon">
                    <i class="fa-light fa-french-fries fa-xl icon"></i>
                    <span class="tooltiptext">Opcionales</span>
                  </div>
                  <div class="tab-name">
                    <span class="name">Opcionales</span>
                  </div>
                  <div class="extend-info">
                    <span class="item-name">Opcionales</span>
                  </div>
                </a>
              </li>
            </ul>
          </a>
        </li>

        <li class="item">
          <a class="item-tab configuraciones" [ngClass]="{ 'show': isConfigRout }">
            <div class="item-header-tab" (click)="expandMenu('configurations', 'configurations', '/configurations')">
              <div class="item-icon">
                <i class="fa-light fa-gear fa-xl icon"></i>
                <i class="fa-solid fa-gear fa-xl icon__active"></i>
                <span class="tooltiptext">Configuraciones</span>
              </div>
              <div class="tab-name">
                <span class="name">CONFIG.</span>
              </div>
              <div class="extend-info">
                <span class="item-name">Configuraciones</span>
              </div>
            </div>
          </a>
        </li>
      </ul>
      <div class="middle-menu"></div>
      <ul class="footer-sidebar">
        <li class="item item-logout">
          <div class="item-tab logout" (click)="openLogout()">
            <div class="item-icon">
              <i class="fa-light fa-right-from-bracket fa-xl i-logout"></i>
              <span class="tooltiptext">Cerrar sesión</span>
            </div>
            <div class="tab-name">
              <span class="name">SALIR</span>
            </div>
            <div class="extend-info">
              <span class="item-name">Cerrar sesión</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>

<p-dialog [(visible)]="display" [draggable]="false" [resizable]="false" [modal]="true"
          class="bid-modal bid-modal__warning--small">
  <ng-template pTemplate="header" class="bid-modal__header"></ng-template>
  <ng-template pTemplate="content" class="bid-modal__content">
    <i class="icon-warning icon-yellow icon-4xl p-my-0"></i>
    <p class="font-poppins text-gray-1 text-lg lh-24 font-semibold margin-top-32 margin-bottom-0">¿Deseas cerrar
      sesión?</p>
  </ng-template>
  <ng-template pTemplate="footer" class="bid-modal__footer">
    <button pButton label="Cancelar" class="btn btn__secondary" (click)="hideDialog()"></button>
    <button pButton label="Cerrar sesión" class="btn btn__primary" (click)="logout()"></button>
  </ng-template>
</p-dialog>
