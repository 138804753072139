import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {FcmService} from 'src/app/services/fcm.service';
import {NotificationsData, UnreadNotifications} from '../../models/NotificationsData';
import {StoreData} from '../../models/auth/StoreData';

@Component({
  selector: 'app-global-alert',
  templateUrl: './global-alert.component.html',
  styleUrls: ['./global-alert.component.scss']
})
export class GlobalAlertComponent implements OnInit, OnDestroy {

  @ViewChild('newOrder', {static: false}) newOrder: ElementRef;
  @ViewChild('hiddenButtonMusic', {static: false}) hiddenButtonMusic: ElementRef;
  subs = [];
  unreadNotifications: Array<UnreadNotifications> = [];
  storeData: StoreData;
  audio: HTMLAudioElement;

  constructor(
    private fcmSrv: FcmService,
    private router: Router,
    private authSrv: AuthService
  ) {
  }

  ngOnInit(): void {
    this.storeData = this.authSrv.getLocalUserData();
    this.checkNotifications();
    this.fcmSrv.notificationsSubject$.subscribe({
      next: () => {
        this.checkNotifications();
      }
    });
    this.fcmSrv.notificationsUpdate$.subscribe({
      next: () => {
        this.unreadNotifications = [];
      }
    });
    this.loadNotificationSound();
  }

  loadNotificationSound() {
    this.audio = new Audio('assets/bell.aac');
    this.audio.load();
  }

  checkNotifications() {
    this.fcmSrv.getUnreadNotifications().subscribe({
      next: (res: NotificationsData) => {
        if (res) {
          this.unreadNotifications = res.unreadNotifications.filter(el => {
            return el.type === 'TABLE' && el.title !== 'Pedido cancelado';
          });
          if (this.storeData && this.storeData.notificationSound && this.unreadNotifications.length > 0) {
            setTimeout(() => {
              this.newOrder.nativeElement.click(); // forced an interaction on UI for make the sound work
            }, 100);
            setTimeout(() => {
              this.hiddenButtonMusic.nativeElement.click();
            }, 2000);
          }
        }
      },
      error: () => {
      }
    });
  }

  async playSound() {
    await this.audio.play();
  }

  async redirectTo() {
    await this.router.navigate(['real-time', 'pedidos-mesa']);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => {
      s.unsubscribe();
    });
  }
}
