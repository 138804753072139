import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {AuthService} from './services/auth.service';
import {FcmService} from './services/fcm.service';
import {MessageService, PrimeNGConfig} from 'primeng/api';
import {SpinnerService} from './services/spinner.service';
import {AngularFireMessaging} from '@angular/fire/compat/messaging';
import {Subject} from 'rxjs';
import {environment} from '../environments/environment';
import firebase from 'firebase/compat';
import {ToastService} from './services/toast.service';
import {RequestErrorTriggerService} from './services/request-error-trigger.service';
import {Amplify} from 'aws-amplify';
import amplify from '../aws-export';
import MessagePayload = firebase.messaging.MessagePayload;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'BlackID - Backoffice';
  showRequestError: boolean;
  errorMessage: string;
  modalTitle: string;
  showSpinner = false;
  logged: boolean;
  showLateralMenu = false;
  eventsUrlSubject: Subject<void> = new Subject<void>();
  isInMaintenance = false;

  constructor(
    private spinnerService: SpinnerService,
    private fcmSrv: FcmService,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    private config: PrimeNGConfig,
    private authService: AuthService,
    public breakpointObserver: BreakpointObserver,
    private toastSrv: ToastService,
    private messageSrv: MessageService,
    private requestErrService: RequestErrorTriggerService
  ) {
    router.events.subscribe({
      next: (val) => {
        // see also
        if (val instanceof NavigationEnd) {
          this.emitEventToChild();
          this.logged = !!localStorage.getItem('isLoggedIn');
        }
      }
    });
    this.authService.userIsLoggedIn$.subscribe({
      next: (res) => {
        this.logged = res;
      }
    });
    this.breakpointObserver.observe(['(min-width: 1025px)']).subscribe((state: BreakpointState) => {
      this.showLateralMenu = state.matches;
    });
  }

  async ngOnInit() {
    Amplify.configure(amplify);
    this.toastSrv.showToast.subscribe({
      next: (toast) => {
        this.showToast(toast.severity, toast.summary, toast.detail);
      }
    });
    this.translate();
    await this.checkMaintenanceMode();

    this.spinnerService.loadSpinner.subscribe({
      next: async (currentState: boolean) => {
        Promise.resolve(null).then(() => {
          this.showSpinner = currentState;
        });
      }
    });

    const logged = localStorage.getItem('isLoggedIn');
    if (logged) {
      this.fcmSrv.initializeToken();
      this.angularFireMessaging.messages.subscribe({
        next: (notifications: MessagePayload) => {
          this.fcmSrv.notificationsSubject$.next(notifications);
        }
      });
    }
  }

  showToast(severity: string, summary: string, detail: string) {
    this.messageSrv.add({
      severity,
      summary,
      detail,
      life: 5000,
      sticky: false,
      closable: true
    });
  }

  async checkMaintenanceMode() {
    if (environment.isUnderMaintenance) {
      this.isInMaintenance = environment.isUnderMaintenance;
      await this.router.navigateByUrl('/maintenance');
    } else {
      this.isInMaintenance = false;
    }
  }

  emitEventToChild() {
    this.eventsUrlSubject.next();
  }

  hideDialogError() {
    this.requestErrService.updateShowError({showError: false, message: ''});
  }

  translate() {
    this.config.setTranslation({
      startsWith: 'Comienza con',
      contains: 'Contiene',
      notContains: 'No contiene',
      endsWith: 'Termina con',
      equals: 'Igual',
      notEquals: 'Diferente',
      noFilter: 'Sin Filtro',
      lt: 'Menor a',
      lte: 'Menor o igual a',
      gt: 'Mayor a',
      gte: 'Mayor o igual a',
      is: 'es',
      isNot: 'No es',
      before: 'Antes',
      after: 'Después',
      clear: 'Limpiar',
      apply: 'Aplicar',
      matchAll: 'Coincidan todos',
      matchAny: 'Coincida alguno',
      addRule: 'Agregar Regla',
      removeRule: 'Quitar Regla',
      accept: 'Si',
      reject: 'No',
      choose: 'Elegir',
      upload: 'Subir',
      cancel: 'Cancelar',
      dayNames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
      monthNames: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ],
      monthNamesShort: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dic',
      ],
      today: 'Hoy',
      weekHeader: 'Sem',
      weak: 'Débil',
      medium: 'Medio',
      strong: 'Fuerte',
      passwordPrompt: 'Ingrese una contraseña',
      // emptyMessage: 'No se han encontrado resultados',
      // emptyFilterMessage: 'No se han encontrado resultados'
    });
  }
}
