import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Storage} from 'aws-amplify';
import {SpinnerService} from './spinner.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  imagePath;
  current = 0;
  max = 100;

  constructor(
    private spinnerSrv: SpinnerService
  ) {
  }

  getUrl(url) {
    return `${environment.mediaUrl}${url}`;
  }

  // This method puts image in AWS server
  saveImageInAmplify(fileNamePrefix: string, file): Promise<string> {
    const baseUrlUploadImage = environment.prefixBucketImages + '/uploads/';
    this.spinnerSrv.loadSpinner.next(true);
    const customPrefix = {
      public: baseUrlUploadImage,
      protected: baseUrlUploadImage,
      private: baseUrlUploadImage,
    };
    const progressCallback = progress => {
      const value = Math.round((progress.loaded / progress.total) * 100);
      this.current = value;
      console.log(`Progress: ${value}%`);
    };
    const temporalImageName = fileNamePrefix + Date.now().toString();
    return Storage.put(temporalImageName, file, {
      progressCallback,
      contentType: 'image/jpeg',
      acl: 'public-read', // Permiso publico de imagen
      customPrefix, // For customize path
    })
      .then((result: any) => {
        // console.log('Success result =>', result);
        this.spinnerSrv.loadSpinner.next(false);
        this.imagePath = 'uploads/' + result.key;
        return this.imagePath;
      })
      .catch(err => {
        console.log('error =>', err);
        this.spinnerSrv.loadSpinner.next(false);
        return null;
      });
  }

}
