<div class="ui active centered p-d-inline loader" *ngIf="loading"></div>

<div class="container">
  <div class="form__container">
    <div class="form__header">
      <a href="/">
        <img src="../../../../assets/images/iso-logo.png" class="logo" alt="BLACK iD"/>
      </a>
    </div>
    <div class="form__wrapper">
      <div class="form__title">
        <p class="text-gray-1 text-lg lh-24 font-semibold my-0">
          Ingresa a tu cuenta
        </p>
      </div>
      <form class="form" [formGroup]="loginForm" (ngSubmit)="submitLogin()">
        <bid-input
          [type]="'text'"
          [name]="'email'"
          [page]="'login'"
          [label]="'Email'"
          [controlName]="'email'"
          [parentForm]="loginForm"
          [largeMobile]="true"
          [preventSpace]="true"
          [readonly]="isReadOnly"
          [onFocus]="true"
          [bgLightDesktop]="true"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
        ></bid-input>
        <bid-input-password
          [page]="'login'"
          [controlName]="'password'"
          [parentForm]="loginForm"
          [label]="'Contraseña'"
          [readonly]="isReadOnly"
          [largeMobile]="true"
          [bgLightDesktop]="true"
          [ctrl]="loginForm.get('password')"
          [formHasBeenSubmitted]="formHasBeenSubmitted"
          [customClass]="'margin-bottom-24'"
        ></bid-input-password>

        <div class="w-100 p-d-flex p-jc-center">
          <button pButton type="submit"
                  label="Ingresar"
                  class="btn btn__primary"></button>
        </div>
        <div class="error__login p-mt-4" *ngIf="showError">
          <div class="icon__warning">
            <i class="fa-solid fa-triangle-exclamation fa-lg text-error p-mr-2"></i>
          </div>
          <span>{{ contentError }}</span>
          <div class="close">
            <i class="fa-light fa-xmark fa-xs text-gray-1" (click)="displayError()"></i>
          </div>
        </div>
      </form>
      <div class="recover_password">
        <a (click)="onForgetPassword()" class="text-link font-semibold">Olvidé mi contraseña</a>
      </div>
    </div>
  </div>
  <div class="create_account">
    <p class="text-base text-gray-1 mb-2">
      ¿No tenés una cuenta?
      <a [routerLink]="['/register']" class="text-link font-semibold">Registrate gratis</a>
    </p>
  </div>
  <p-dialog header="Atención" [(visible)]="displayDialog" [style]="{ width: '400px' }">
    {{ contentDialog }}
  </p-dialog>
</div>
