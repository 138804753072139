import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Configurations} from '../../../core/models/configurations/Configurations.interface';
import {AuthService} from '../../../services/auth.service';
import {BidAccessLoginCredentialsRequest} from '../../../core/models/BidAccessLoginCredentialsRequest';
import {BidAccessLoginCredentialsResponse} from '../../../core/models/BidAccessLoginCredentialsResponse';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private canLogout = true;
  configHasBeenUpdated$ = new Subject();
  cancelChanges = new Subject();
  saveChanges$ = new Subject();
  someFormHasError$ = new Subject<string>();
  editBidCredentialsModal = new Subject();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public getConfigurations(idStore: number): Observable<Configurations> {
    return this.http.get<Configurations>(environment.apiUrl + '/stores/configuration/' + idStore);
  }

  public updateConfigurations(payload, id): Observable<any> {
    return this.http.patch<any>(environment.apiAGUrl + '/stores/configuration/' + id, payload, {observe: 'response'});
  }

  public updatePassword(form) {
    const {id} = this.authService.getLocalUserData();
    return this.http.post(environment.apiAGUrl + '/store/changePasswordConfiguration/' + id, form);
  }

  public createOrUpdateBidAccessCredentials(payload: BidAccessLoginCredentialsRequest) {
    const {id} = this.authService.getLocalUserData();
    return this.http.post(environment.apiAGUrl + '/stores/bid-access/' + id, payload);
  }

  public getBidAccessCredentials(): Observable<BidAccessLoginCredentialsResponse> {
    const {id} = this.authService.getLocalUserData();
    return this.http.get<BidAccessLoginCredentialsResponse>(environment.apiAGUrl + '/stores/bid-access/' + id);
  }

  public deleteBidAccessCredentials() {
    const {id} = this.authService.getLocalUserData();
    return this.http.delete(environment.apiAGUrl + '/stores/bid-access/' + id);
  }

  setCanLogout(state: boolean) {
    this.canLogout = state;
  }

  getCanLogout() {
    return this.canLogout;
  }

  // Helpers
  objectsHaveDifferences(first, second) {
    for (const key in first) {
      if (second.hasOwnProperty(key)) {
        if (first[key] !== second[key]) {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }
}
