export class StoreData {
  address: string;
  taxIdentifier: string;
  email: string;
  id: number;
  jwt: string;
  latitude: number;
  logo: string;
  logoUrl: string;
  header: string;
  headerUrl: string;
  longitude: number;
  name: string;
  password: string;
  phone: number;
  city: string;
  maxPerTableBooking: number;
  notificationSound: boolean;
  currency: string;
}

export class Store {
  address: string;
  taxIdentifier: string;
  email: string;
  id: number;
  latitude: number;
  logo: string;
  header: string;
  longitude: number;
  name: string;
  phone: number;
  city: string;
  enabled: boolean;
  accountVerified: boolean;
}
